import React from 'react';
import ipfsToPath from "../../utils/ipfsToPath";
import {useIsPrimary} from "../../hooks/useIsPrimary";
import {useTranslation} from "react-i18next";
import {useAuctionDetails} from "../../hooks/useAuctionDetails";

function Poster({wrapperClass, nft}) {
    const isPrimary = useIsPrimary(nft?.tokenId);
    const auction = useAuctionDetails(nft?.sale?.saleId);
    const { t } = useTranslation();

    return (
        <div className={wrapperClass}>
            <div className="border border-solid border-white rounded-50 p-8 relative">
                <img
                    className="w-full rounded-40"
                    style={{maxHeight: 490, objectFit: "contain"}}
                    src={ipfsToPath(nft?.file)}
                    alt={nft?.title}
                />

                {(!isPrimary && auction?.royalty) && (
                    <div className="absolute w-full bottom-0 right-0 left-0 flex items-center justify-center pb-3">
                        <div className="primary-box">
                            {t('productPage.poster.primary', { royalty: auction?.royalty?.div(100).toString() || 0 })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}


export default Poster;
