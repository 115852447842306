import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../layouts/MainLayout/MainLayout';
import BidCard from '../../components/common/BidCard';
import {useDispatch, useSelector} from "react-redux";
import {fetchLiveAuctions, fetchNFTs} from "../../state/marketplace/actions";
import Spinner from "../../components/common/Spinner";
import Empty from "../../components/common/Empty";

let isLoading = false;
function LiveAuctionsPage() {
  const { t } = useTranslation();
  const auctions = useSelector(state => state.marketplace.liveAuctions);
  const loader = useRef(null)
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    hasMore: true,
    pageNumber: 1,
    pageSize: 12,
    params: {}
  })

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);


  useEffect(() => {
    fetchAuctionsHandler(query);
  }, [query])


  const fetchAuctionsHandler = async (query) => {
    if(query.hasMore) {
      isLoading = true;
      const res = await dispatch(fetchLiveAuctions({
        pageNumber: query.pageNumber,
        pageSize: query.pageSize,
        status: 'live',
        ...query.params,
      }))

      if(!res || (res && res?.length < query.pageSize)) {
        setQuery(q => ({
          ...q,
          hasMore: false,
        }))
      }

      isLoading = false;
    }
  }

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading) {
      setQuery(q => {
        if(q.hasMore) {
          return {
            ...q,
            pageNumber: q.pageNumber + 1,
          }
        }
        return q;
      })
    }
  };

  return (
    <Layout mainClassName="mt-1" displayStickySidebar>
      <div className="text-24 text-blue font-semibold">{t('header.liveAuctions')}</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-y-9 gap-x-8 md:gap-x-12 mt-16 md:mt-8">
        {auctions?.map((nft) => (
            <div key={nft._id}>
              <BidCard
                  id={nft._id}
                  contentClass="lightShadow p-6"
                  details={nft}
                  type={'auction'}
                  height={286}
              />
            </div>
        ))}
      </div>
      {auctions?.length === 0 && !query.hasMore && (
          <Empty description={t('errors.noItem', { page: "Auction" })} />
      )}
      <div className="flex items-center justify-between py-5 flex-col">
        <div ref={loader} />
        {query.hasMore && (
            <Spinner />
        )}
      </div>
    </Layout>
  );
}

export default LiveAuctionsPage;
