import { createAction } from "@reduxjs/toolkit";
import marketPlaceHandler from "../../api/marketPlace";
import {toast} from "react-toastify";
import userHandler from "../../api/user";
import configHandler from "../../api/config";

export const setLiveAuctions = createAction('@marketplace/live-auctions');
export const updateLiveAuctions = createAction('@marketplace/live-auctions-update');
export const setNFTs = createAction('@marketplace/nft');
export const updateNFTs = createAction('@marketplace/nft-update');
export const setUserNFTs = createAction("@marketplace/user-nft");
export const updateUserNFTs = createAction('@marketplace/user-nft-update');
export const updateLeaderboard = createAction("@marketplace/leaderboard");
export const updateConfig = createAction("@marketplace/config");


export const fetchLiveAuctions = (params, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.liveAuctions(params);
            if(res) {
                if(params?.pageNumber === 1) {
                    dispatch(setLiveAuctions(res?.data))
                } else {
                    dispatch(updateLiveAuctions(res?.data));
                }
            }

            return res?.data;
        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchLiveAuctions(params, false));
                }, 500)
            } else {
                toast.error("Error on get Live Auctions");

                return false;
            }

        }
    }
}

export const fetchNFTs = (params, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.getNFTs(params);
            if(res) {
                if(params?.pageNumber === 1) {
                    dispatch(setNFTs(res?.data))
                } else {
                    dispatch(updateNFTs(res?.data));
                }
            }
            return res?.data;

        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchNFTs(params, false));
                }, 500)
            } else {
                toast.error("Error on get NFTs");

                return false;
            }

        }
    }
}

export const fetchUserNFTs = (account, params, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.getNFTs({
                ...params,
                owner: `string(${account?.toLowerCase()})`,
            });
            if(res) {
                if(params?.pageNumber === 1) {
                    dispatch(setUserNFTs({
                        account,
                        nfts: res?.data
                    }))
                } else {
                    dispatch(updateUserNFTs({
                        account,
                        nfts: res?.data
                    }));
                }
            }

            return res?.data;

        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchUserNFTs(account, params, false));
                }, 500)
            } else {
                toast.error("Error on get NFTs");

                return false;
            }

        }
    }
}

export const fetchUserSales = (account, params, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.liveSales({
                ...params,
                seller: `string(${account?.toLowerCase()})`,
                status: 'live'
            });

            return res?.data;

        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchUserSales(account, params, false));
                }, 500)
            } else {
                toast.error("Error on get Sale orders");

                return false;
            }

        }
    }
}


export const fetchUserMintedNFTs = (account, params, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.getNFTs({
                ...params,
                creator: `string(${account?.toLowerCase()})`,
            });

            return res?.data;

        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchUserMintedNFTs(account, params, false));
                }, 500)
            } else {
                toast.error("Error on get NFTs");

                return false;
            }

        }
    }
}

export const fetchSingleSale = (saleId, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.singleLiveSale(saleId);

            return res?.data;
        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchSingleSale(saleId, false));
                }, 500)
            } else {
                toast.error("Error on get Auction details");

                return false;
            }
        }
    }
}

export const fetchSingleNFT = (tokenId, reFetch = true) => {
    return async (dispatch) => {
        try {
            const res = await marketPlaceHandler.getSingleNFT(tokenId);

            return res?.data;
        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchSingleNFT(tokenId, false));
                }, 500)
            } else {
                toast.error("Error on get NFT details");

                return false;
            }
        }
    }
}

export const fetchLeaderboard = (reFetch = true) => {
    return async (dispatch) => {
        try {
            const bestBuyers = await userHandler.getAll({
                sort: "-spent",
                pageSize: 12
            })

            const bestSellers = await userHandler.getAll({
                sort: "-received",
                pageSize: 12,
            })

            const data = {
                bestBuyers: bestBuyers?.data,
                bestSellers: bestSellers?.data
            }

            dispatch(updateLeaderboard(data))

            return data;
        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchLeaderboard(false));
                }, 500)
            } else {
                toast.error("Error on get Leaderboard");

                return false;
            }
        }
    }
}

export const fetchUsers = (config, reFetch = true) => {
    return async (dispatch) => {
        try {
            const users = await userHandler.getAll(config)


            return users?.data;
        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchUsers(config, false));
                }, 500)
            } else {
                toast.error("Error on get Users");

                return false;
            }
        }
    }
}

export const fetchBannerConfig = (reFetch = true) => {
    return async (dispatch) => {
        try {
            const bannerConfig = await configHandler.getBanner()

            dispatch(updateConfig({ banner: bannerConfig?.data }))
            return bannerConfig?.data;
        } catch(e) {
            if(reFetch) {
                setTimeout(() => {
                    dispatch(fetchBannerConfig(false));
                }, 500)
            } else {
                return false;
            }
        }
    }
}
