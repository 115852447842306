import httpInstance from "../utils/httpInstance";

export class StaticServer {
    constructor(baseURL = `https://api.clubrare.wolfelabs.tech/api/v1/static-server`) {
        this.instance = httpInstance({
            baseURL,
            timeout: 15000
        })

        this.defaultConfig = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        this.baseURL = baseURL;
    }

    uploadFile(file, jwt, config = this.defaultConfig) {
        const form = new FormData();
        form.append('pic', file);

        const reqConfig = this._includeJWT(jwt, config)
        return this.instance.post('/pics', form, reqConfig);
    }

    getFile(path, config = {}) {
        return this.instance.get(path, config);
    }

    getFileURL(path) {
        return this.baseURL + path
    }

    _includeJWT(jwt, config = this.defaultConfig) {
        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${jwt}`
            }
        }
    }
}

const staticServer = new StaticServer();

export default staticServer;
