import React, { useState, useEffect } from 'react';
import Layout from '../../layouts/MainLayout/MainLayout';
import Banner from './Banner';
import TopMembers from './TopMembers';
import LiveAuctions from '../../components/common/LiveAuctions';
import Explore from '../../components/common/Explore';
import {useDispatch} from "react-redux";
import {fetchBannerConfig, fetchLeaderboard, fetchLiveAuctions} from "../../state/marketplace/actions";

export default function Home() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  const fetchHomeData = async () => {
    setLoading(true);
    const promises = [];
    promises.push(dispatch(fetchLiveAuctions({
        pageSize: 12,
        pageNumber: 1,
        status: 'live',
      }))
    )
    promises.push(dispatch(fetchLeaderboard()))
    promises.push(dispatch(fetchBannerConfig()))
    await Promise.all(promises);
    setLoading(false);
  }

  useEffect(() => {
    fetchHomeData()
  }, []);

  return (
    <Layout mainClassName="" loading={loading}>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-6 lg:gap-y-0 lg:gap-x-4 xl:gap-x-8">
        <Banner wrapperClass="col-span-1 lg:col-span-7" />
        <TopMembers wrapperClass="col-span-5" />
      </div>
      <LiveAuctions wrapperClass="mt-14 w-full" />
      <Explore wrapperClass="mt-22" />
    </Layout>
  );
}
