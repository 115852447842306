import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import arrow from '../../assets/icons/arrowColorful.svg';
import bag1 from '../../assets/images/products/bag-1.png';
import bag2 from '../../assets/images/products/bag-2.png';
import watch from '../../assets/images/products/watch-1.png';
import BidCard from './BidCard';
import RouteMap from '../../routes/RouteMap';
import {useSelector} from "react-redux";
import Empty from "./Empty";

function LiveAuctions({ wrapperClass }) {
  const { t } = useTranslation();
  const liveAuctions = useSelector(state => state.marketplace.liveAuctions);

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    variableWidth: true,
    slidesToScroll: 1
  };


  return (
    <div className={wrapperClass}>
      <div className="flex justify-between items-center">
        <div className="text-18 lg:text-24 text-blue font-semibold">{t('home.LiveAuctions')}</div>
        <Link to={RouteMap.liveAuctions.index}>
          <div className="flex items-center space-x-1">
            <div className="hidden md:block textGradient text-18 text-blue font-semibold">
              {t('home.LiveAuctions')}
            </div>
            <img src={arrow} alt="arrow" />
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-1 mt-8 slick-slides-gap--lg c-slick-dots c-slick-dots-bottom--55">
        <Slider {...settings}>
          {liveAuctions?.map((auction, _i) => {
            return (
                <BidCard
                    key={auction._id + _i}
                    id={auction._id + _i}
                    headerClass="px-6 mt-6"
                    contentClass="p-0"
                    wrapperClass="slider-card"
                    height={286}
                    details={auction}
                    type={'auction'}
                    isLiveAuction
                />
            )
          })}
        </Slider>
      </div>

      {liveAuctions.length === 0 && (
          <Empty  description={t('errors.noItem', { page: "Auction" })} />
      )}
    </div>
  );
}

export default LiveAuctions;
