import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Select from '../components/UI/Select';
import Layout from '../layouts/MainLayout/MainLayout';
import userHandler from "../api/user";
import {useActiveWeb3React} from "../hooks/useActiveWeb3React";
import WithAuth from "../components/hoc/withAuth";
import {toast} from "react-toastify";
import {handleSign} from "../utils/handleSign";
import marketPlaceHandler from "../api/marketPlace";
import {useSelector} from "react-redux";
import {useMarketContract, useNFTContract} from "../hooks/useContract";
import {sendTransactionHandler} from "../utils/sendTransactionHandler";
import {NetworkRPC, NFTContractAddress, supportedNFTFormats} from "../constants/contracts";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import Spinner from "../components/common/Spinner";
import Loading from "../components/UI/Loading";
import {AddressZero} from "@ethersproject/constants";
import {useNFTMint} from "../hooks/useNFTMint";
import {useReserveAuctionCreator} from "../hooks/useReserveAuctionCreator";

const web3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider(NetworkRPC))

const defaultProperties = [
  {
    name: "",
    value: ""
  }
]

function Create() {
  const { t } = useTranslation();
  const { account, library } = useActiveWeb3React();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const [activeSaleModel, setActiveSaleModel] = useState('fixed-price');
  const jwt = useSelector(state => state.user.accessToken);
  const nftContract = useNFTContract();
  const marketContract = useMarketContract();
  const [mintLoading, mintCallback] = useNFTMint();
  const [auctionLoading, auctionHandler] = useReserveAuctionCreator();


  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState('');
  const [properties, setProperties] = useState(defaultProperties)

  const salesModels = [
    { key: 'fixed-price', title: t('create.FixedPrice') },
    { key: 'auction', title: t('create.Auction') },
  ];

  const startingDateOptions = [
    { name: 'Right after listing', value: t('create.RightAfterListing') },
  ];

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const checkValue = (obj) => {
    return (obj.name?.trim()?.length > 0 && obj?.value?.trim()?.length > 0)
  }

  const checkProperties = p => {
    let filteredProperties = p.filter(item => {
      return (item.name?.trim()?.length > 0 || item?.value?.trim()?.length > 0)
    })
    const filledProperties = filteredProperties.filter(item => checkValue(item));
    if(filledProperties.length === filteredProperties.length) {
      filteredProperties = filteredProperties.concat({
        name: "",
        value: ""
      })
    }


    return filteredProperties;
  }

  const propertyChangeHandler = (initialProperties) => {
    if(initialProperties.length === 1) {
      if(checkValue(initialProperties[0])) {
        return initialProperties.concat({
          name: "",
          value: ""
        })
      }

      return initialProperties;
    } else {
      return checkProperties(initialProperties);
    }
  }

  const onFillProperty = (field, value, index) => {
    setProperties(p => {
      const newProperties = p.map((item, _i) => {
        if(_i === index) {
          return {
            ...item,
            [field]: value,
          }
        }

        return item;
      })

      return propertyChangeHandler(newProperties);
    })
  }

  const onFileUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const onMintAndAuction = async (ipfsPath, data, type = 'auction') => {
    const tokenId = await nftContract.getNextTokenId();
    await mintCallback({
      args: [ipfsPath],
      message: t("messages.minted")
    })
    const auctionRecipe = await auctionHandler({
      tokenId,
      data,
      type,
      message: t("messages.orderCreated")
    });

    return auctionRecipe ? auctionRecipe?.transactionHash : null;
  }

  const onSubmit = async (data) => {
    if(!selectedFile) {
      toast.error(t('errors.uploadFile'));
      return false;
    }
    if(!supportedNFTFormats.includes(selectedFile?.type)) {
      toast.error(t('errors.unsupportedExtension'));
      return false;
    }
    setSubmitLoading(true);
    let marketplaceRes;
    try {
      const res = await userHandler.getNonce(account);
      const signBody = await handleSign(account, res?.data?.nonce);

      const body = new FormData();
      body.append('wallet', signBody.wallet);
      body.append('signature', signBody.signature);
      body.append('message', signBody.message);
      body.append('title', data.title);
      body.append('description', data.description);
      body.append('category', data.category);
      body.append('file', selectedFile);
      if(properties.length > 1 || (properties.length === 1 && checkValue(properties[0]))) {
        const filledProperties = properties.filter(item => checkValue(item));
        for(let i in filledProperties) {
          body.append(`properties[${i}][name]`, filledProperties[i].name);
          body.append(`properties[${i}][value]`, filledProperties[i].value);
        }
      }

      marketplaceRes = await marketPlaceHandler.createNFT(
          body,
          jwt,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
      })
    } catch(e) {
      setSubmitLoading(false);
      return false;
    }

    let res;
    try {
      res = await onMintAndAuction(marketplaceRes?.data?.uri, data, activeSaleModel);
    } catch(e) {
      res = false;
    }
    setSubmitLoading(false);
    if(res) {
      setSelectedFile(undefined);
      setPreview('');
      reset();
    }

  }

  return (
    <Layout mainClassName="" displayStickySidebar>
      <div className="text-18 md:text-24 text-blue font-semibold mb-9 md:mb-15">
        {t('create.CreateACollectible')}
      </div>
      <form
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row-start-2 md:row-start-1 xl:pr-10">
          <div className="text-16 md:text-18 text-blue font-semibold mb-2.5 mt-8 md:mt-0">
            {t('create.UploadYourArtwork')}
          </div>
          <div className="flex mb-2.5">
            <div className="text-14 md:text-16 text-blue opacity-80 mr-2">
              PNG, GIF, WEBP, MP4 or MP3
            </div>
            <div className="text-14 md:text-16 text-blue font-semibold mb-2.5 opacity-50">
              {t('create.Max30mb')}
            </div>
          </div>
          <div className="grid grid-cols-1 justify-items-start items-center mt-6.5">
            <input
              className="custom-file-input text-transparent cursor-pointer row-start-1 col-start-1 w-full
              font-semibold rounded-12 bg-white bg-opacity-20 border border-solid border-white h-25"
              type="file"
              accept="image/*"
              onChange={onFileUpload}
            />
            <div className="row-start-1 col-start-1 justify-self-center z-10 text-16 md:text-20 text-blue pointer-events-none">
              {t('create.ChooseFile')}
            </div>
          </div>
          <div className="text-18 text-blue font-semibold mt-9">
            {t('create.ChooseYourSalesModel')}
          </div>
          <div className="flex flex-col space-y-6 mt-6 mb-9">
            {salesModels?.map((m) => (
              <button
                key={m.key}
                className={`w-full text-16 md:text-18 font-semibold rounded-12 py-4 md:py-3.5 border border-solid border-white ${
                  m.key === activeSaleModel
                    ? 'text-white linearGradient'
                    : 'text-blue bg-white bg-opacity-20'
                }`}
                onClick={() => setActiveSaleModel(m.key)}
              >
                {m.title}
              </button>
            ))}
          </div>
          {activeSaleModel === 'fixed-price' && (
            <>
              <div className="text-16 md:text-18 text-blue font-semibold mb-5.5">
                {t('create.Price')}
              </div>
              <div className="relative">
                <input
                  className="responsive-placeholder bg-transparent border-b border-solid border-white w-full"
                  placeholder="0.00 ETH"
                  {...register('price', { required: true })}
                />
              </div>
              {errors.price && (
                <p className="text-red justify-self-start mt-2 pl-2">
                  {t('create.PleaseEnterPrice')}
                </p>
              )}
            </>
          )}
          {activeSaleModel === 'auction' && (
            <div className="flex flex-col space-y-5 w-full">
              <div className="">
                <div className="text-16 md:text-18 text-blue font-semibold mt-5 mb-4">
                  {t('create.MinimumBid')}
                </div>
                <input
                    className="responsive-placeholder bg-transparent border-b border-solid border-white
                    w-full"
                    placeholder={'0.00 ETH'}
                    {...register('reservedPrice', { required: true })}
                />
              </div>
              <div className="flex flex-col justify-between w-full">
                <div className="w-full">
                  <div className="text-18 text-blue font-semibold mt-5 mb-4">
                    {t('create.StartingDate')}
                  </div>
                  <Select options={startingDateOptions} width="w-36 sm:w-42" />
                </div>
              </div>
            </div>
          )}
          <div className="text-16 md:text-18 text-blue font-semibold mt-9 mb-5.5">
            {t('create.Title')}
          </div>
          <input
            className="responsive-placeholder bg-transparent border-b border-solid border-white
                    w-full"
            placeholder={t('create.28Characters')}
            {...register('title', { required: true })}
          />
          {errors.title && (
            <p className="text-red justify-self-start mt-2 pl-2">{t('create.PleaseEnterTitle')}</p>
          )}

          <div className="text-16 md:text-18 text-blue font-semibold mt-9 mb-5.5">
            {t('create.Description')}
          </div>
          <textarea

            className="responsive-placeholder bg-transparent border-b border-solid border-white
                    focus:outline-none w-full"
            placeholder={t('create.Description')}
            {...register('description', { required: true })}
          />
          {errors.description && (
            <p className="text-red justify-self-start mt-2 pl-2">
              {t('create.PleaseEnterDescription')}
            </p>
          )}

          <div className="">
            <div className="text-16 md:text-18 text-blue font-semibold mt-9 mb-5.5">
              {t('create.Category')}
            </div>

            <input
                className="responsive-placeholder bg-transparent border-b border-solid border-white
                    w-full"
                placeholder={t('create.Category')}
                {...register('category', { required: true })}
            />
            {errors.description && (
                <p className="text-red justify-self-start mt-2 pl-2">
                  {t('create.PleaseEnterCategory')}
                </p>
            )}
          </div>

          <div className="text-16 md:text-18 text-blue font-semibold mt-9 mb-5.5">
            {t('create.Royalties')}
          </div>
          <div className="relative">
            <input
              className="responsive-placeholder bg-transparent border-b border-solid border-white w-full"
              placeholder="10"
              {...register('royalties', { required: true })}
            />
            <div className="absolute top-0 right-0 text-gray3">%</div>
          </div>
          {errors.royalties && (
            <p className="text-red justify-self-start mt-2 pl-2">
              {t('create.PleaseEnterRoyalties')}
            </p>
          )}

          <div className="w-full">
            <div className="mt-9 mb-5.5">
              <span className="text-16 md:text-18 text-blue font-semibold mr-2">
                {t('create.Properties')}
              </span>
              <span className="text-14 text-blue opacity-50">{t('create.Optional')}</span>
            </div>
            {properties?.map((property, index) => {
              return (
                  <div className="flex justify-between space-x-16 mb-6">
                    <div className={'w-full'}>
                      <input
                          className="responsive-placeholder bg-transparent border-b border-solid border-white
                    w-full"
                          placeholder={t('create.Size')}
                          value={property.name}
                          onChange={e => onFillProperty('name', e.target.value, index)}
                      />
                    </div>
                    <div className={'w-full'}>
                      <input
                          className="responsive-placeholder bg-transparent border-b border-solid border-white
                    w-full"
                          placeholder="M"
                          value={property.value}
                          onChange={e => onFillProperty('value', e.target.value, index)}
                      />
                    </div>
                  </div>
              )
            })}
          </div>

          <button
            className="w-full text-16 md:text-14 text-white linearGradient mb-12
            flex items-center justify-center
            font-bold rounded-12 py-4 md:py-3.5 mt-17"
            type="submit"
          >
            {submitLoading ? (<Loading margin={'0'} size={'16px'}/>) : t('create.CreateItem')}
          </button>
        </div>
        <div className="row-start-1 md:row-start-1 xl:col-span-2 justify-self-end md:pl-6 xl:pl-40 w-full">
          <div className="text-16 md:text-18 text-blue font-semibold">Preview</div>
          <div className="grid grid-cols-1 justify-items-center items-center">
            {selectedFile ? (
              <img
                className="row-start-1 col-start-1 w-full rounded-50 bg-white bg-opacity-20 mt-6.5 h-60 md:h-140 object-contain"
                src={preview}
              />
            ) : (
              <div className="row-start-1 col-start-1 w-full rounded-50 bg-white bg-opacity-20 mt-6.5 h-60 md:h-140"></div>
            )}
            {!selectedFile && (
              <div className="row-start-1 col-start-1 z-10 text-14 md:text-16 xl:text-20 text-gray pointer-events-none">
                {t('create.UploadFileToPreview')}
              </div>
            )}
          </div>
        </div>
      </form>
    </Layout>
  );
}

export default WithAuth(Create);
