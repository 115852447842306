export class Currency {
    /**
     * The only instance of the base class `Currency`.
     */
    static ETHER = new Currency(18, 'BNB', 'BNB')

    /**
     * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
     * @param decimals decimals of the currency
     * @param symbol symbol of the currency
     * @param name of the currency
     */
    constructor(decimals, symbol = "", name = "") {
        this.decimals = decimals
        this.symbol = symbol
        this.name = name
    }
}

const ETHER = Currency.ETHER
export { ETHER }