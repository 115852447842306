import React, {useMemo, useState} from 'react';
import Slider from 'react-slick';
import avatar2 from '../../assets/images/avatar-2.png';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import getFileURL from "../../utils/getFileURL";
import {shortenAddress} from "../../utils/address";
import RouteMap from "../../routes/RouteMap";
import {bindWith} from "comma-number";
import {useHistory} from "react-router-dom";

const format = bindWith(',', '.');

function TopMembers({ wrapperClass }) {
  const { t } = useTranslation();
  const [topMembersType, setTopMembersType] = useState(1);
  const leaderboard = useSelector(state => state.marketplace.leaderboard);
  const history = useHistory();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "w-full"
  };

  const memberTypes = [
    { key: 1, title: t('home.topMembers.sellers') },
    { key: 2, title: t('home.topMembers.buyers') },
  ];

  const users = useMemo(() => {
    if(!leaderboard.bestBuyers && !leaderboard.bestSellers) {
      return [];
    }

    const members = topMembersType === 1 ? leaderboard?.bestSellers : leaderboard?.bestBuyers;

    let result = [];
    try {

      for(let i = 0; i < members.length; i+=4) {
        const item = [];
        for(let j = i; j < Math.min(members.length, i + 4); j++) {
          item.push(members[j]);
        }

        result.push(item);
      }
    } catch(e) {

      result = [];
    }

    return result;
  }, [leaderboard, topMembersType])

  return (
    <div className={wrapperClass}>
      <div
        className="relative bg-white bg-opacity-20 border border-solid border-white rounded-50
      py-4 xl:py-5 px-2 xs:px-2 xl:px-4 lightShadow"
        style={{ height: 282 }}
      >
        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <div className="text-20 lg:text-24 text-blue font-semibold mr-3">
              {t('home.topMembers.top')}
            </div>
            <div className="flex border border-solid border-white rounded-12 h-9">
              {memberTypes?.map((i) => (
                <div
                  key={i.key}
                  className={`text-14 font-semibold rounded-12 flex items-center cursor-pointer flex justify-center ${
                    topMembersType === i.key
                      ? 'text-white border-r border-solid border-white linearGradient'
                      : 'text-blue'
                  }`}
                  style={{ width: 86 }}
                  onClick={() => setTopMembersType(i.key)}
                >
                  {i.title}
                </div>
              ))}
            </div>
          </div>
          <div
            className="z-10 grid grid-cols-1 c-slick-dots c-slick-dots-bottom--40 w-full
           px-0 xs:px-10 sm:px-32 md:px-48 lg:px-0 mt-4 xl:mt-4"
          >
            <Slider {...settings}>
              {users?.map((list, i) => (
                <div key={i}>
                  <div
                    key={i}
                    className="w-full grid grid-cols-2 gap-x-2.5 xs:gap-x-3.5 md:gap-x-2 xl:gap-x-4 gap-y-8.5"
                  >
                    {list?.map((user, _i) => (
                      <div key={user._id} className="cursor-pointer" onClick={() => history.push(RouteMap.user(user._id))}>
                        <div className="flex justify-center mt-2 w-full md:px-8">
                          <div className="relative -mr-4 lg:-mr-3.5 xl:-mr-4">
                            <img
                              className="border-r border-solid border-white rounded-full w-11.5 h-11.5 md:w-14 md:h-14 z-10 object-cover bg-white"
                              src={user?.avatar ? getFileURL(user?.avatar) : avatar2}
                              alt=""
                            />
                            <div className="absolute -top-2 left-0 text-white">{i * 4 + _i + 1}.</div>
                          </div>
                          <div
                              className="rounded-40 py-1.5 md:py-2 flex-grow pl-4 pr-3 xs:px-5.5 md:px-4 lg:pl-3.5 lg:pr-3 xl:px-8 border border-solid border-white"
                          >
                            <div className="text-14 text-center text-blue">{user?.name || shortenAddress(user.wallet)}</div>
                            <div className="text-12 text-center opacity-60">
                              {topMembersType === 1 ? (`$${format(user?.received || 0.00)}`) : (`$${format(user?.spent || 0.00)}`)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="blueBgShadow"></div>
      </div>
    </div>
  );
}

export default TopMembers;
