import {createContext, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {stepStatus} from "../state/preferences/reducer";
import {createSteps, failStep, nextStep, resetSteps, retryStep} from "../state/preferences/actions";

const StepsContext = createContext({
    passedSteps: [],
    currentStep: {},
    nextSteps: {},
    currentStepStatus: stepStatus.NOT_STARTED,
    nextStep: () => {},
    createSteps: (steps) => {},
    onFailStep: () => {},
    onResetStep: () => {},
    onRetryStep: () => {}
})

const StepsProvider = ({ children }) => {
    const passedSteps = useSelector(state => state.preferences.passedSteps);
    const currentStep = useSelector(state => state.preferences.currentStep);
    const nextSteps = useSelector(state => state.preferences.nextSteps);
    const currentStepStatus = useSelector(state => state.preferences.currentStepStatus);
    const dispatch = useDispatch();

    const createStepsHandler = useCallback((steps) => {
        dispatch(createSteps({ steps }))
    }, [dispatch])

    const nextStepHandler = useCallback(() => {
        dispatch(nextStep())
    }, [dispatch])

    const failStepHandler = useCallback(() => {
        dispatch(failStep())
    }, [dispatch])

    const retryStepHandler = useCallback(() => {
        dispatch(retryStep())
    }, [dispatch])

    const resetStepsHandler = useCallback(() => {
        dispatch(resetSteps())
    }, [dispatch])

    return (
        <StepsContext.Provider
            value={{
                passedSteps,
                currentStep,
                nextSteps,
                currentStepStatus,
                nextStep: nextStepHandler,
                createSteps: createStepsHandler,
                onFailStep: failStepHandler,
                onResetStep: resetStepsHandler,
                onRetryStep: retryStepHandler
            }}
        >
            {children}
        </StepsContext.Provider>
    )
}

export {
    StepsProvider,
    StepsContext
}