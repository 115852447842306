import firebase from 'firebase/app';
import 'firebase/messaging';
import firebaseHandler from "../api/firebase";

const firebaseConfig = {
    apiKey: "AIzaSyAGIyjZwxPOsW4HWKh2mYmmDchbnCZ3fqw",
    authDomain: "clubrare-5363d.firebaseapp.com",
    projectId: "clubrare-5363d",
    storageBucket: "clubrare-5363d.appspot.com",
    messagingSenderId: "579532715542",
    appId: "1:579532715542:web:aae79b2728afd585cd2737",
    measurementId: "G-YPYVQP4VNJ"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const requestPermissionForNotification = () =>
    new Promise((resolve, reject) => {
        Notification
            .requestPermission()
            .then((permission) => {
                if(permission === 'granted') {
                    resolve(true);
                }
                reject(false)
            })
            .catch(err => {
                console.log(err);

                reject(false);
            })
    })

export const getToken = (jwt) =>
    new Promise((resolve, reject) => {
        if(!jwt) {
            reject("Not LoggedIn")
        }
        messaging
            .getToken({ vapidKey: "BPcIyMSL1BNY3NIUkXGuiSj1UIjwkhxVkOQEeLvyp2uQ4yYlwOh0jQuRUB-QIBXsw_KsBAENctzfoEGJ5B8zMY0" })
            .then(async (firebaseToken) => {
                const notifRes = await firebaseHandler.sendToken(firebaseToken, jwt);
                if(notifRes) {
                    resolve(firebaseToken);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const getTestNotification = (jwt) => {
    return new Promise(async (resolve, reject) => {
        try {
            const notifRes = await firebaseHandler.test({
                title: "Test Title",
                body: "Test body",
                data: {
                    _id: "test_id",
                    avatar: "test_avatar"
                }
            }, jwt)

            console.log(notifRes);
            resolve(notifRes);
        } catch(e) {
            reject(e);
        }
    })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
