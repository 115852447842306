import React, {useEffect, useMemo, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import switchBox from '../../assets/icons/switchBox.svg';
import { CryptoIcon } from '../common/Icons';
import Countdown from "react-countdown";
import moment from "moment";
import BigNumber from "bignumber.js";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useSelector} from "react-redux";
import Web3 from "web3";
import {NetworkRPC} from "../../constants/contracts";
import commaNumber from "comma-number";
import {useMarketContract} from "../../hooks/useContract";
import {toast} from "react-toastify";
import isAuctionLive from "../../utils/isAuctionLive";
import RouteMap from "../../routes/RouteMap";
import {useHistory} from "react-router-dom";
import {sendTransactionHandler} from "../../utils/sendTransactionHandler";
import Loading from "../UI/Loading";
import useModal from "../../hooks/useModal";
import {APP_NAME} from "../../constants";
import ipfsToPath from "../../utils/ipfsToPath";
import ShareModal from "../common/ShareModal";

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
        <div className="text-14 md:text-24 text-blue">
          Auction Ended
        </div>
    );
  } else {
    return (
        <div className="flex items-end space-x-2">
          <div className="text-14 md:text-24 text-blue flex space-x-3">
            <div className="">{days}d</div>
            <div className="">{hours}h</div>
            <div className="">{minutes}m</div>
            <div className="">{seconds}s</div>
          </div>
          <div className="text-12 md:text-14 text-blue opacity-70 md:pb-1">
            Till End
          </div>
        </div>
    );
  }
};

const ShareButton = ({ onToggle }) => {
  return (
      <div onClick={onToggle} className={'cursor-pointer'}>
        <img className="w-12 h-12" src={switchBox} alt="switch box" />
      </div>
  )
}

const format = commaNumber.bindWith(',', '.')
const web3 = new Web3(new Web3.providers.HttpProvider(NetworkRPC))

function AuctionSummary({ wrapperClass, onSetBidOpen, onSetBidModalTitle, nft }) {
    const history = useHistory();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
      control,
    formState: { errors },
  } = useForm();
  const marketContract = useMarketContract();
  const [openShareModal, toggleShareModal] = useModal();

  const userBid = useWatch({
    control,
    defaultValue: '',
    name: "bid"
  })
  const { chainId } = useActiveWeb3React();
  const ethPrice = useSelector(state => state.blockchain.ethPrice[chainId || 1]);
  const sale = nft?.sale || {};
  const [minBid, setMinBid] = useState(0);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { account, library } = useActiveWeb3React();

  useEffect(() => {
    if(chainId) marketContract.getMinBidAmount(sale?.saleId).then(res => setMinBid(res?.toString())).catch(e => setMinBid(0));
  }, [marketContract, nft, chainId])

  const onSubmit = (data) => {
    const bidInWei = new BigNumber(web3.utils.toWei(data.bid));
    const minBidBigNumber = new BigNumber(minBid);
    if(bidInWei.lt(minBidBigNumber)) {
      toast.error("Bid amount is too low");
      return false;
    }

    onSetBidOpen(bidInWei.toString());
  };


  const nftPrice = useMemo(() => {
    if(nft && ((nft?.sale?.type === 'auction' && nft?.sale?.reservePrice) || (nft?.sale?.type === 'fixed-price' && nft?.sale?.price))) {
      let price = nft?.sale?.reservePrice || nft?.sale?.price;
      if(nft?.sale?.bids?.length > 0) {
        price = nft?.sale?.bids[nft?.sale?.bids?.length - 1]?.amount || nft?.sale?.reservePrice || nft?.sale?.price ;
      }

      return web3.utils.fromWei(price);
    }
    return 0
  }, [nft])

  const nftValue = useMemo(() => {
    return new BigNumber(nftPrice).times(ethPrice || 1).toFixed(2);
  }, [ethPrice, nftPrice])

  const bidValue = useMemo(() => {
    return new BigNumber(userBid || 0).times(ethPrice || 1).toFixed(2);
  }, [userBid, ethPrice])

  const onCancelOrder = async (auctionId) => {
    setCancelLoading(true);
    const methodName = "cancelReserveAuction";
    const args = [
        auctionId
    ]

    const res = await sendTransactionHandler(marketContract, methodName, args)
    const hash = res?.hash || false;

    if(!hash) {
      setCancelLoading(false);
      return false;
    }

    try {
      const recipe = await library.waitForTransaction(hash);
      setCancelLoading(false);
      toast.success(t('productPage.auctionState.cancelled'));
      setIsCancelled(true);
    } catch(e) {
      setCancelLoading(false);
      toast.error(e.message || t('errors.default'));
    }

  }

  const shareURL = useMemo(() => {
    if(nft?._id) {
      const origin = window.location.origin;

      return `${origin}/token/${nft?._id}`
    }

    return ""
  }, [nft?._id])

  return (
    <div className={wrapperClass}>
      <div className="bg-white rounded-32 md:rounded-50 p-5 md:p-8">
        <div className="flex justify-between items-center">
          <div className="text-18 md:text-24 text-blue font-semibold">
            {nft?.title}
          </div>
          {sale?.type === 'auction' && (
              <Countdown
                  date={moment(sale?.startDate).add(Math.floor(sale?.duration / 86400), 'days')}
                  renderer={CountdownRenderer}
              />
          )}
        </div>
        {sale?._id && (
            <>
              {(sale?.bids
                  && sale?.bids?.length > 0
                  && isAuctionLive(sale)
                  && sale?.type === 'auction'
              ) && (
                  <div className="text-12 md:text-16 text-blue mt-8.5">
                    {t('productPage.auctionSummary.CurrentBid')}
                  </div>
              )}
              {(sale?.status === 'completed' || sale?.status === 'claimed') && (
                  <div className="text-12 md:text-16 text-blue mt-8.5">
                    {t('productPage.auctionSummary.SoldFor')}
                  </div>
              )}
              {(isAuctionLive(sale) && sale?.type === 'fixed-price') && (
                  <div className="text-12 md:text-16 text-blue mt-8.5">
                    {t('productPage.auctionSummary.Price')}
                  </div>
              )}
              {(!sale?.bids || sale?.bids?.length === 0 && sale?.type === 'auction') && (
                  <div className="text-12 md:text-16 text-blue mt-8.5">
                    {t('productPage.auctionSummary.MinimumBid')}
                  </div>
              )}
              <div className={(sale?.status === 'completed' || sale?.status === 'claimed') ? 'flex justify-between items-center' : ''}>
                <div
                    className={`flex mt-1 ${(sale?.status === 'completed' || sale?.status === 'claimed')
                        ? 'flex-col'
                        : 'justify-between items-center'}`
                    }
                >
                  <div className="flex items-center space-x-1">
                    <div className="text-16 md:text-22 text-blue font-bold">{nftPrice}</div>
                    <CryptoIcon size="29" />
                  </div>
                  <div className="text-12 md:text-16 text-blue">${format(nftValue)}</div>
                </div>
                {(sale?.status === 'completed' || sale?.status === 'claimed') && <ShareButton onToggle={toggleShareModal}/>}
              </div>
              {(sale?.bids
                  && sale?.bids?.length > 0
                  && isAuctionLive(sale)
                  && sale?.type === 'auction'
              ) && (
                  <p className="text-12 md:text-15 text-blue opacity-80 mt-3">
                    {t('productPage.auctionSummary.currentBidText')} {minBid && minBid !== '0' ? `(${web3.utils.fromWei(minBid)})` : null}
                  </p>
              )}
              {(!sale?.bids || sale?.bids?.length === 0 && sale?.type === 'auction') && (
                  <p className="text-15 text-blue opacity-80 mt-3">
                    {t('productPage.auctionSummary.minimumBidText')} {minBid && minBid !== '0' ? `(${web3.utils.fromWei(minBid)})` : null}
                  </p>
              )}
              {(!sale?.bids || sale?.bids?.length === 0 && sale?.type === 'fixed-price') && (
                  <p className="text-15 text-blue opacity-80 mt-3">
                    {t('productPage.auctionSummary.priceText')} {minBid && minBid !== '0' ? `(${web3.utils.fromWei(minBid)})` : null}
                  </p>
              )}
            </>
        )}
        {!account ? (
            <div className="flex items-center space-x-5 w-full mt-8">
              <button
                  className="w-full text-16 md:text-20 text-white font-semibold bg-blue rounded-12 rounded-bl-30 rounded-br-30 py-4 md:py-3.5"
                  style={{ boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)' }}
                  onClick={() => {
                    history.push(RouteMap.connectWallet);
                  }}
              >
                {t('header.connect')} Wallet
              </button>
            </div>
        ) : account?.toLowerCase() === sale?.seller?.toLowerCase() ? (
            <>
              {isAuctionLive(sale) && sale?.type === 'auction' && (
                  <div className="flex items-center space-x-5 w-full mt-8">
                    <button
                        className="w-full text-16 md:text-20 text-white font-semibold bg-blue rounded-12 rounded-bl-30 rounded-br-30 py-4 md:py-3.5 flex items-center justify-center"
                        style={{ boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)' }}
                        onClick={() => {
                          if(sale?.bids && sale?.bids?.length > 0) {
                            toast.error("The Auction is in progress. You can't cancel it at this moment.")
                            return false;
                          }

                          onCancelOrder(sale?.saleId);
                        }}
                        disabled={isCancelled}
                    >
                      {cancelLoading ? (<Loading margin={'0'} size={'20px'}/>) : t('productPage.auctionSummary.cancelOrder')}
                    </button>
                  </div>
              )}
              {(sale?.type === 'fixed-price' && isAuctionLive(sale)) && (
                  <div className="flex items-center space-x-5 w-full mt-8">
                    <button
                        className="w-full text-16 md:text-20 text-white font-semibold bg-blue rounded-12 rounded-bl-30 py-4 md:py-3.5 flex items-center justify-center"
                        style={{ boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)' }}
                        onClick={onCancelOrder.bind(this, sale?.saleId)}
                        disabled={isCancelled}
                    >
                      {cancelLoading ? (<Loading margin={'0'} size={'20px'}/>) : t('productPage.auctionSummary.cancelOrder')}
                    </button>
                    <ShareButton onToggle={toggleShareModal}/>
                  </div>
              )}
            </>
        ) : (
            <>
              {isAuctionLive(sale) && sale?.type === 'auction' && (
                  <form className="mt-3.5 w-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative">
                      <div className="absolute top-5.5 left-4">
                        <CryptoIcon fill="#8E8E8E" />
                      </div>
                      <input
                          className="lgPlaceholder bg-transparent border border-solid border-blue rounded-12
                   pl-9 pr-5 w-full h-15 pt-2"
                          placeholder="0.00"
                          {...register('bid', { required: true })}
                      />
                      <div className="absolute top-4 right-4 text-gray3 text-22">${format(bidValue)}</div>
                    </div>
                    {errors.bid && (
                        <p className="text-red justify-self-start mt-2 pl-2">
                          {t('productPage.auctionSummary.PleaseEnterAnAmount')}
                        </p>
                    )}

                    <div className="flex items-center space-x-5 w-full mt-8">
                      <button
                          className="w-full text-16 md:text-20 text-white font-semibold bg-blue rounded-12 rounded-bl-30 py-4 md:py-3.5"
                          style={{ boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)' }}
                          type="submit"
                      >
                        {t('productPage.auctionSummary.PlaceABid')}
                      </button>
                      <ShareButton onToggle={toggleShareModal}/>
                    </div>
                  </form>
              )}
              {(sale.type === 'fixed-price' && isAuctionLive(sale)) && (
                  <div className="flex items-center space-x-5 w-full mt-8">
                    <button
                        className="w-full text-16 md:text-20 text-white font-semibold bg-blue rounded-12 rounded-bl-30 py-4 md:py-3.5"
                        style={{ boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)' }}
                        onClick={() => {
                          onSetBidOpen(minBid);
                          onSetBidModalTitle('Checkout');
                        }}
                    >
                      {t('productPage.auctionSummary.BuyNow')}
                    </button>
                    <ShareButton onToggle={toggleShareModal}/>
                  </div>
              )}
            </>
        )}
        <ShareModal
          link={shareURL}
          type={"NFT"}
          open={openShareModal}
          onDismiss={toggleShareModal}
          optParams={{
            title: sale?.status === 'live' ? `${nft?.title} is on Sale! Check it on ${APP_NAME}` : `Check ${nft?.title} on ${APP_NAME}`,
            quote: sale?.status === 'live' ? `${nft?.title} is on Sale! Check it on ${APP_NAME}` : `Check ${nft?.title} on ${APP_NAME}`,
            media: ipfsToPath(nft?.file) || undefined
          }}
        />
      </div>
    </div>
  );
}

export default AuctionSummary;
