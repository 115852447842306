import React, {useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useForm, useWatch} from 'react-hook-form';
import Select from '../UI/Select';
import {MarketContractAddress, NetworkRPC, NFTContractAddress} from "../../constants/contracts";
import BigNumber from "bignumber.js";
import {sendTransactionHandler} from "../../utils/sendTransactionHandler";
import Web3 from "web3";
import {toast} from "react-toastify";
import Loading from "../UI/Loading";
import {useMarketContract, useNFTContract} from "../../hooks/useContract";
import {useSelector} from "react-redux";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import commaNumber from "comma-number";
import {AddressZero} from "@ethersproject/constants";

const web3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider(NetworkRPC))
const format = commaNumber.bindWith(',', '.')

function PutOnSale({nft}) {
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {errors},
    } = useForm();
    const priceWatch = useWatch({
        control,
        defaultValue: '',
        name: 'price'
    })
    const { account, chainId, library } = useActiveWeb3React();
    const [activeSaleModel, setActiveSaleModel] = useState('fixed-price');
    const [submitLoading, setSubmitLoading] = useState(false);
    const marketContract = useMarketContract();
    const nftContract = useNFTContract();
    const ethPrice = useSelector(state => state.blockchain.ethPrice[chainId || 1]);

    const salesModels = [
        {key: 'fixed-price', title: t('productPage.PutOnSale.FixedPrice')},
        {key: 'auction', title: t('productPage.PutOnSale.Auction')},
    ];

    const startingDateOptions = [
        {name: 'Right after listing', value: t('productPage.PutOnSale.rightAfterListing')},
    ];


    const onReserveAuction = async (tokenId, data, type = 'auction') => {
        const methodName = 'createReserveAuction';
        const args = [
            NFTContractAddress,
            tokenId?.toString(),
            web3.utils.toWei((activeSaleModel === 'auction' ? data?.reservedPrice : data?.price) || '1'),
            AddressZero,
            type,
            new BigNumber(data?.royalties).times(100).toFixed(0)
        ];

        const res = await sendTransactionHandler(marketContract, methodName, args);

        const {hash} = res;
        if (!hash) {
            return false;
        }

        return hash;
    }

    const onApproveToken = async (tokenId) => {
        const methodName = 'approve';
        const args = [
            MarketContractAddress,
            tokenId?.toString()
        ];
        const res = await sendTransactionHandler(nftContract, methodName, args);

        const {hash} = res;
        if (!hash) {
            return false;
        }

        return hash;
    }


    const onSubmit = async (data) => {
        setSubmitLoading(true);
        let res;
        try {
            const approveHash = await onApproveToken(nft?.tokenId);
            await library.waitForTransaction(approveHash);

            res = await onReserveAuction(nft?.tokenId, data, activeSaleModel);
        } catch (e) {
            console.log(e);
            res = false;
        }
        setSubmitLoading(false);
        if (res) {
            reset();
            toast.success("Your Request submitted!");
        } else {
            toast.error(t('errors.default'));
        }

    }

    const priceValue = useMemo(() => {
        return new BigNumber(priceWatch || 0).times(ethPrice).toFixed(2)
    }, [ethPrice, priceWatch])

    return (
        <div
            className="w-full border border-solid border-white bg-white bg-opacity-20 rounded-50 py-2 md:py-9 px-4 md:px-8 lg:px-12">
            <div className="mt-9 md:mt-0 mb-4">{t('productPage.PutOnSale.ChooseYourSalesModel')}</div>
            <div className="flex flex-col space-y-6">
                {salesModels?.map((m) => (
                    <button
                        key={m.key}
                        className={`w-full text-16 md:text-18 font-semibold rounded-12 py-4 md:py-3.5 border border-solid border-white ${
                            m.key === activeSaleModel
                                ? 'text-white linearGradient'
                                : 'text-blue bg-white bg-opacity-20'
                        }`}
                        onClick={() => setActiveSaleModel(m.key)}
                    >
                        {m.title}
                    </button>
                ))}
            </div>
            <form className="mt-3.5 w-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                {activeSaleModel === 'fixed-price' && (
                    <>
                        <div className="text-18 text-blue font-semibold mt-5">
                            {t('productPage.PutOnSale.Price')}
                        </div>
                        <div className="relative mt-3">
                            <input
                                className="responsive-placeholder bg-transparent border-b-2 border-solid border-white
                   py-1.5 w-full"
                                placeholder="0.00"
                                {...register('price', {required: true})}
                            />
                            <div className="absolute top-2 right-0 flex items-center space-x-2">
                                <div className="text-gray3">${format(priceValue)}</div>
                            </div>
                        </div>
                        {errors.price && (
                            <p className="text-red justify-self-start mt-2 pl-2">
                                {t('productPage.PutOnSale.PleaseEnterPrice')}
                            </p>
                        )}
                    </>
                )}
                {activeSaleModel === 'auction' && (
                    <div className="flex flex-col space-y-5 w-full">
                        <div className="">
                            <div className="text-18 text-blue font-semibold mt-5 mb-4">
                                {t('productPage.PutOnSale.MinimumBid')}
                            </div>
                            <div className="flex items-center justify-between mt-3">
                                <div className="w-full">
                                    <input
                                        className="responsive-placeholder bg-transparent border-b-2 border-solid border-white w-full"
                                        placeholder="Enter minimum bid"
                                        {...register('reservedPrice', {required: true})}
                                        style={{height: 26}}
                                    />
                                    {errors.minimumBidText && (
                                        <p className="text-red justify-self-start mt-2 pl-2">
                                            {t('productPage.PutOnSale.PleaseEnterPrice')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between w-full">
                            <div className="w-full">
                                <div className="text-18 text-blue font-semibold mb-4">
                                    {t('productPage.PutOnSale.StartingDate')}
                                </div>
                                <Select
                                    options={startingDateOptions}
                                    width="w-full"
                                    selectedClass="text-14 sm:text-16 pb-1"
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-18 text-blue font-semibold mt-5">
                    {t('create.Royalties')}
                </div>
                <div className="relative mt-3">
                    <input
                        className="responsive-placeholder bg-transparent border-b-2 border-solid border-white
                   py-1.5 w-full"
                        placeholder="10"
                        {...register('royalties', {required: true})}
                    />
                    <div className="absolute top-2 right-0 flex items-center space-x-2">
                        <div className="text-gray3">%</div>
                    </div>
                </div>
                {errors.royalties && (
                    <p className="text-red justify-self-start mt-2 pl-2">
                        {t('create.PleaseEnterRoyalties')}
                    </p>
                )}
                <button
                    className="w-full text-16 md:text-20 text-white font-semibold
                    flex items-center justify-center
                    bg-blue rounded-12 rounded-b-30 py-4 md:py-3.5 mt-10"
                    style={{boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)'}}
                    type="submit"
                >
                    {submitLoading ? (<Loading margin={'0'} size={'16px'}/>) : t('productPage.PutOnSale.PutOnSale')}
                </button>
            </form>
        </div>
    );
}


export default PutOnSale;
