import Web3 from "web3";
import {toast} from "react-toastify";

export const handleSign = async (address, nonce) => {
    try {
        const web3 = new Web3(Web3.givenProvider);
        if(!web3) {
            toast.error("Please connect your wallet first!")
            return Promise.reject("Please connect your wallet first!");
        }
        const signature = await web3?.eth.personal.sign(
            nonce,
            address,
            null
        );

        return {
            wallet: address,
            signature,
            message: nonce,
        };
    } catch (err) {
        throw new Error(
            'You need to sign the message to be able to log in.'
        );
    }
}
