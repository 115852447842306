import {getAddress} from "@ethersproject/address";

export const shortenAddress = (address, length = 4) => {
    return address?.slice(0, length + 2) + "..." + address?.slice(-length);
}

export function validateAndParseAddress(address) {
    try {
        return getAddress(address)
    } catch (error) {
        return false;
    }
}