import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useAuthentication} from "./hooks";
import {disconnectWallet} from "./actions";
import checkExpiredJWT from "../../utils/checkExpiredJWT";

export default function Updater() {
    const dispatch = useDispatch();
    const { account } = useActiveWeb3React();
    const address = useSelector(state => state.user.accountAddress);
    const accessToken = useSelector(state => state.user.accessToken);
    const expireDate = useSelector(state => state.user.expireDate);
    const [isLoggedIn, handleAuthorization] = useAuthentication()

    useEffect(() => {
        if(
            (account?.toLowerCase() !== address?.toLowerCase() )
            || (accessToken && checkExpiredJWT(expireDate))
        ) {
            dispatch(disconnectWallet())
        }
    }, [account, accessToken, expireDate])

    useEffect(() => {
        if(!isLoggedIn) {
            handleAuthorization()
        }
    }, [account, isLoggedIn])

    return null
}
