import getFileURL from "../../utils/getFileURL";
import avatar from "../../assets/images/avatar-2.png";
import tickBox from "../../assets/icons/tickBox.svg";
import React from "react";
import {shortenAddress} from "../../utils/address";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import layers from "../../assets/icons/layers.svg";
import switchBox from "../../assets/icons/switchBox.svg";
import RouteMap from "../../routes/RouteMap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function UserCard({
    profile,
    id
}) {
    const { t } = useTranslation();

    return (
        <div
            style={{
                maxHeight: 380,
                minHeight: 260
            }}
            className="bg-white bg-opacity-20 flex flex-col p-8 items-center
            space-y-4 rounded-50 border border-white border-solid"
        >
            <Link to={RouteMap.user(id)} className="relative">
                <img className="rounded-full w-17 h-17 sm:w-19 sm:h-19 object-cover" src={profile?.avatar ? getFileURL(profile?.avatar) : avatar} alt="avatar" />
                {profile?.isVerified && (
                    <img className="absolute bottom-0 right-0" src={tickBox} alt="tick box" />
                )}
            </Link>

            <Link to={RouteMap.user(id)} className="text-22 text-blue font-semibold text-center">{profile?.name}</Link>

            <p className="text-16 sm:text-16 text-blue text-center sm:text-left opacity-75">
                {profile?.bio}
            </p>
            <div className="flex-grow"/>
            <div className="flex items-center justify-between space-x-3 self-stretch pt-4">
                <div
                    className="flex items-center space-x-2 linearGradient rounded-12
                        flex-grow px-2 h-12"
                >
                    <div className="text-12 sm:text-14 text-white flex-grow">
                        {shortenAddress(profile?.wallet, 4)}
                    </div>
                    <CopyToClipboard text={profile?.wallet} onCopy={() => toast.success(t('profile.summary.copied'))} >
                        <img className="cursor-pointer" src={layers} alt="layers" />
                    </CopyToClipboard>
                </div>
                {profile?.customURL && (
                    <a href={profile?.customURL} target={"_blank"} rel={'noreferrer noopener'}>
                        <img className="w-12 h-12" src={switchBox} alt="switch box" />
                    </a>
                )}
            </div>
        </div>
    )
}