import closeBox from "../../assets/icons/closeBox.svg";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import SVG from "react-inlinesvg";

import {facebookLink, pinterestLink, telegramLink, twitterLink} from "../../constants/share";

import PinterestIcon from '../../assets/icons/pinterest.svg';
import FacebookIcon from '../../assets/icons/facebook.svg';
import TelegramIcon from '../../assets/icons/telegram.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import Modal from "../UI/Modal";


export const ShareTypes = {
    'pinterest': {
        key: "pinterest",
        icon: PinterestIcon,
        title: "Pinterest",
        handler: pinterestLink
    },
    'facebook': {
        key: "facebook",
        icon: FacebookIcon,
        title: "Facebook",
        handler: facebookLink
    },
    'telegram': {
        key: "telegram",
        icon: TelegramIcon,
        title: "Telegram",
        handler: telegramLink
    },
    'twitter': {
        key: "twitter",
        icon: TwitterIcon,
        title: "Twitter",
        handler: twitterLink
    }
}

export function ShareItem({ icon, title, handler, link, optParams = {} }) {
    const handleOpen = useCallback(() => {
            window.open(handler(link, optParams), "_blank")
    }, [handler, link, optParams])
    return (
        <button
            className="flex flex-col items-center justify-center p-3 text-blue rounded-12 transition
                transition-300 focus:outline-none hover:bg-lightGray bg-opacity-20 space-y-3 bg-white border-0"
            onClick={handleOpen}
        >
            <SVG src={icon} width={36} height={36} />
            <span>{title}</span>
        </button>
    )
}

export default function ShareModal({
    link,
    optParams = {},
    open,
    onDismiss,
    type = "Profile",
    shareItems = [ShareTypes.pinterest, ShareTypes.facebook, ShareTypes.telegram, ShareTypes.twitter]
}) {
    const { t } = useTranslation();

    const title = (
        <div className="grid grid-cols-5 items-center border-gray w-full border-b border-solid border-fadeBlue">
            <div className="col-start-2 col-span-3 text-18 md:text-22 text-center font-semibold pb-4.5 pt-6">
                {t('global.share', { type })}
            </div>
            <div
                className="col-start-5 justify-self-end mr-9 cursor-pointer"
                onClick={() => {
                    onDismiss();
                }}
            >
                <img className="" src={closeBox} alt="close"/>
            </div>
        </div>
    )

    const content = (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 pt-6 px-8 pb-11">
            {shareItems.map(item => {
                return (
                    <ShareItem
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                        optParams={optParams}
                        handler={item.handler}
                        link={link}
                    />
                )
            })}
        </div>
    )


    return (
        <Modal
            title={title}
            open={open}
            content={content}
            onCloseModal={onDismiss}
            containerClass="rounded-50"
        />
    );
}