import httpInstance from "../utils/httpInstance";

export class ConfigApi {
    constructor(baseURL = `https://api.clubrare.wolfelabs.tech/api/v1/configs`) {
        this.instance = httpInstance({
            baseURL,
            timeout: 15000
        })

        this.defaultConfig = {
            headers: {
                "Content-Type": "application/json"
            }
        }

    }

    getBanner(config = this.defaultConfig) {
        return this.instance.get(`/banner`, config);
    }

}

const configHandler = new ConfigApi();

export default configHandler;
