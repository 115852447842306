export default {
  home: '/home',
  notifications: '/notifications',
  search(type = ":type?") {
    return `/search/${type}`
  },
  liveAuctions: {
    index: '/auctions',
    view(auctionId) {
      return `/auction/${auctionId}`;
    },
  },
  nft: {
    view(auctionId) {
      return `/token/${auctionId}`;
    },
  },
  user(userId) {
    return `/user/${userId}`
  },
  explore: '/explore',
  create: '/create',
  connectWallet: '/connect-wallet',
  claimingNFT: {
    index: '/claim',
    success: '/claim/success',
  },
  profile: {
    index: '/profile',
    edit: '/profile/edit',
    upvote: '/profile/upvote',
    communityUpvote: '/profile/community-upvote',
    verifyProfile: {
      index: '/profile/verify',
      viaTwitter: '/profile/verify/twitter',
    },
  },
};
