import React, {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import avatar from '../../assets/images/avatar-2.png';
import { CryptoIcon } from '../common/Icons';
import {shortenAddress} from "../../utils/address";
import moment from "moment";
import commaNumber from "comma-number";
import Web3 from "web3";
import {NetworkRPC} from "../../constants/contracts";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useSelector} from "react-redux";
import BigNumber from "bignumber.js";
import getFileURL from "../../utils/getFileURL";
import {Link} from "react-router-dom";
import RouteMap from "../../routes/RouteMap";
import Empty from "../common/Empty";

const format = commaNumber.bindWith(',', '.')
const web3 = new Web3(new Web3.providers.HttpProvider(NetworkRPC));

function History({
  wrapperClass,
  bids= [],
  saleStatus,
    saleType = 'fixed-price'
}) {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();
  const ethPrice = useSelector(state => state.blockchain.ethPrice[chainId || 1]);

  const HistoryItem = ({ wrapperClass, bid }) => {
    const bidPrice = useMemo(() => {
      return web3.utils.fromWei(bid?.amount)
    }, [bid])
    const bidValue = useMemo(() => {
      return new BigNumber(bidPrice).times(ethPrice).toFixed(2);
    }, [bidPrice, ethPrice])

    return (
      <div className={wrapperClass}>
        <div className="flex w-full items-center">
          <img
              className="w-16 h-16 md:w-21 md:h-21 -mr-8 z-30 border border-solid border-white rounded-full"
              src={bid?.avatar ? getFileURL(bid?.avatar) : avatar}
              alt="avatar"
          />
          <div
              className="w-full flex justify-between items-center border border-solid border-white
                bg-white bg-opacity-20 rounded-50 py-1 md:py-3 pl-9 pr-4 xl:pr-10"
          >
            <div className="">
              <div className="flex flex-col md:flex-row">
                <div className="text-10 md:text-14 lg:text-18 text-blue opacity-80 mr-2">
                  {t('productPage.history.BidPlacedBby')}
                </div>
                {bid?._id ? (
                    <Link to={RouteMap.user(bid?._id)} className="text-14 md:text-14 lg:text-18 text-blue font-semibold">
                      @{bid?.name ? bid?.name : shortenAddress(bid?.wallet)}
                    </Link>
                ) : (
                    <span className="text-14 md:text-14 lg:text-18 text-blue font-semibold">
                    @{bid?.name ? bid?.name : shortenAddress(bid?.wallet)}
                  </span>
                )}
              </div>
              <div className="text-12 lg:text-16 md:text-blue opacity-60 md:mt-1.5">
                {moment(bid?.date).format('MMM D, YYYY [at] h:mma')}
              </div>
            </div>
            <div className="">
              <div className="flex items-center space-x-2">
                {saleStatus !== 'live' ? (
                    <div className="text-18 text-blue font-semibold">
                      {t('productPage.history.SoldFor')} <span className="ml-2">{format(bidPrice)}</span>
                    </div>
                ) : (
                    <div className="text-14 md:text-20 text-blue font-bold">{format(bidPrice)}</div>
                )}
                <CryptoIcon size="29" />
              </div>
              <div className="text-12 md:text-16 text-blue opacity-60">${format(bidValue)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return saleType === 'auction' ? (
    <div className={wrapperClass}>
      <div className="text-18 md:text-24 text-blue font-semibold md:pl-8 mb-5">History</div>
        <div className="flex flex-col space-y-8">
          {bids?.map((bid, i) => (
            <HistoryItem key={i} bid={bid} />
          ))}
          {bids?.length === 0 && saleStatus === 'live' && saleType === 'auction' && (
              <Empty
                title={t("productPage.history.noBid")}
                description={t("productPage.history.noBidDescription")}
              />
          )}
        </div>
    </div>
  ) : null;
}

export default History;
