import objectToGetParams from "../utils/objectToGetParams";
import assert from "../utils/assert";

function emailLink(url, { subject, body, separator }) {
    return 'mailto:' + objectToGetParams({ subject, body: body ? body + separator + url : url });
}


function facebookMessengerLink(url, { appId, redirectUri, to }) {
    return (
        'https://www.facebook.com/dialog/send' +
        objectToGetParams({
            link: url,
            redirect_uri: redirectUri || url,
            app_id: appId,
            to,
        })
    );
}


function facebookLink(url, { quote, hashtag }) {
    assert(url, 'facebook.url');

    return (
        'https://www.facebook.com/sharer/sharer.php' +
        objectToGetParams({
            u: url,
            quote,
            hashtag,
        })
    );
}


function telegramLink(url, { title }) {
    assert(url, 'telegram.url');

    return (
        'https://telegram.me/share/url' +
        objectToGetParams({
            url,
            text: title,
        })
    );
}


function twitterLink(
    url,
    {
        title,
        via,
        hashtags = [],
        related = [],
    },
) {
    assert(url, 'twitter.url');
    assert(Array.isArray(hashtags), 'twitter.hashtags is not an array');
    assert(Array.isArray(related), 'twitter.related is not an array');

    return (
        'https://twitter.com/share' +
        objectToGetParams({
            url,
            text: title,
            via,
            hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
            related: related.length > 0 ? related.join(',') : undefined,
        })
    );
}


function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

function whatsappLink(url, { title, separator }) {
    assert(url, 'whatsapp.url');
    return (
        'https://' +
        (isMobileOrTablet() ? 'api' : 'web') +
        '.whatsapp.com/send' +
        objectToGetParams({
            text: title ? title + separator + url : url,
        })
    );
}


function pinterestLink(
    url,
    { media, description },
) {
    assert(url, 'pinterest.url');
    assert(media, 'pinterest.media');

    return (
        'https://pinterest.com/pin/create/button/' +
        objectToGetParams({
            url,
            media,
            description,
        })
    );
}

export {
    facebookMessengerLink,
    facebookLink,
    twitterLink,
    telegramLink,
    pinterestLink,
    whatsappLink,
    emailLink,
}