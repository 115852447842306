import {useNFTContract} from "./useContract";
import {useCallback, useMemo} from "react";
import {sendTransactionHandler} from "../utils/sendTransactionHandler";
import {useWaiter} from "./useWaiter";
import {toast} from "react-toastify";
import {useActiveWeb3React} from "./useActiveWeb3React";
import {useLoadingCallback} from "./useLoadingCallback";

export const useNFTMint = () => {
    const nftContract = useNFTContract();
    const waiter = useWaiter();
    const { chainId, library, account } = useActiveWeb3React();
    const [loading, callbackHandler] = useLoadingCallback();

    const callback = useCallback(async ({
        args,
        approveMarket = true,
        waitForTransaction = true,
        message = undefined
    }) => {
        if(!account || !library || !chainId) return false;

        const methodName = approveMarket ? 'mintAndApproveMarket' : 'mint';
        const res = await sendTransactionHandler(nftContract, methodName, args);

        if(waitForTransaction) {
            const result = await waiter(res);

            if(result && message) {
                toast.success(message);
            }
            return result || false;
        }

        if(res && message) {
            toast.success(message);
        }
        return res;
    }, [nftContract, waiter, library, chainId, account])

    return useMemo(() => [loading, (...args) => callbackHandler(callback, args)], [loading, callbackHandler, callback]);
}