import {useEffect, useMemo, useState} from "react";
import {useMarketContract} from "./useContract";
import {useActiveWeb3React} from "./useActiveWeb3React";
import {NFTContractAddress} from "../constants/contracts";

export const useIsPrimary = (tokenId) => {
    const [isPrimary, setIsPrimary] = useState(true);
    const marketContract = useMarketContract();
    const { chainId, account } = useActiveWeb3React();

    useEffect(() => {
        if(!account || !chainId) return false;

        const args = [
            NFTContractAddress,
            tokenId,
        ]
        marketContract.getIsPrimary(...args)
            .then(res => {
                setIsPrimary(!!res);
            })
            .catch(err => {
                setIsPrimary(true)
            })
    }, [tokenId, chainId, account])

    return useMemo(() => isPrimary, [isPrimary]);
}