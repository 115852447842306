import {createSteps, failStep, nextStep, resetSteps, retryStep, successSteps} from "./actions";
import {createReducer} from "@reduxjs/toolkit";

export const stepStatus = {
    "NOT_STARTED": "NOT_STARTED",
    "IN_PROGRESS": "IN_PROGRESS",
    "FAILED": "FAILED",
    "FINISHED": "FINISHED",
}

const initialState = {
    passedSteps: [],
    currentStep: {},
    nextSteps: [],
    currentStepStatus: stepStatus.NOT_STARTED,
}

export default createReducer(initialState, builder => {
    return builder
        .addCase(createSteps, (state, action) => {
            const { steps } = action.payload;
            state.passedSteps = [];
            state.currentStep = steps[0];
            state.nextSteps = steps.slice(1) || [];
            state.currentStepStatus = stepStatus.IN_PROGRESS
        })
        .addCase(successSteps, (state, action) => {
            state.passedSteps.push(state.currentStep);
            state.currentStep = {};
            state.nextSteps = [];
            state.currentStepStatus = stepStatus.FINISHED
        })
        .addCase(resetSteps, (state, action) => {
            state.passedSteps = []
            state.currentStep = {}
            state.nextSteps = []
            state.currentStepStatus = stepStatus.NOT_STARTED
        })
        .addCase(nextStep, (state, action) => {
            if(state.currentStep) {
                state.passedSteps.push(state.currentStep);
                if(state.nextSteps.length > 0) {
                    state.currentStep = state.nextSteps[0];
                    state.nextSteps = state.nextSteps.slice(1);
                    state.currentStepStatus = stepStatus.IN_PROGRESS;
                } else {
                    state.currentStep = {};
                    state.currentStepStatus = stepStatus.FINISHED;
                }
            }
        })
        .addCase(failStep, (state, action) => {
            state.currentStepStatus = stepStatus.FAILED;
        })
        .addCase(retryStep, (state, action) => {
            state.currentStepStatus = stepStatus.IN_PROGRESS;
        })
})