import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import openSeaLogo from '../../assets/icons/openSeaLogo.svg';
import avatar from '../../assets/images/avatar-2.png';
import getOpenseaUrl from "../../utils/getOpenseaUrl";
import moment from "moment";
import {Link} from "react-router-dom";
import RouteMap from "../../routes/RouteMap";
import {shortenAddress} from "../../utils/address";
import getFileURL from "../../utils/getFileURL";

function Information({nft}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="order-last md:order-first">
        <div className="flex w-full items-center mt-10.5">
          <img
            className="w-16 h-16 md:w-21 md:h-21 -mr-8 z-30 border border-solid border-white rounded-full object-cover"
            src={nft?.owner?.avatar ? getFileURL(nft?.owner?.avatar) : avatar}
            alt="avatar"
          />
          <div className="w-full border border-solid border-white bg-white bg-opacity-20 rounded-50 py-2.5 md:py-3 pl-10">
            <div className="flex">
              <div className="text-14 md:text-14 xl:text-18 text-blue opacity-80 mr-1">
                <span>{nft?.title}</span>{" "}
                {nft?.owner?.wallet?.toLowerCase() === nft?.creator?.toLowerCase()
                    ? t('productPage.Information.CreatedBy')
                    : t('productPage.Information.OwnedBy')}
              </div>
              {nft?.owner && nft?.owner?._id ? (
                  <Link to={RouteMap.user(nft?.owner?._id)} className="text-14 md:text-14 xl:text-18 text-blue font-semibold">
                    @{nft?.owner?.name ? nft?.owner?.name : nft?.owner ? shortenAddress(nft?.owner?.wallet) : shortenAddress(nft?.creator)}
                  </Link>
              ) : (
                  <span className="text-14 md:text-14 xl:text-18 text-blue font-semibold">
                    @{nft?.owner?.name ? nft?.owner?.name : nft?.owner ? shortenAddress(nft?.owner?.wallet) : shortenAddress(nft?.creator)}
                  </span>
              )}
            </div>
            <div className="text-12 md:text-blue opacity-60 mt-1">
              {moment(nft?.sale?.createdAt).format('MMM D, YYYY [at] h:mma')}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-11.5">
        <div className="text-18 md:text-24 text-blue font-semibold">
          {t('productPage.Information.Information')}
        </div>
        <div className="bg-white rounded-50 mt-7 flex flex-col items-stretch">
          <div className="flex items-center justify-between px-8 pt-8 pb-4 border-b border-opacity-20 border-blue">
            <div className="text-16 md:text-22 text-blue font-semibold">{nft?.title}</div>
            <div className="text-12 md:text-16 text-blue font-bold opacity-50">
              <span className="mr-1">{t('productPage.Information.Edition')}</span> 1
              <span className="mx-1">{t('productPage.Information.of')}</span> 1
            </div>
          </div>
          {nft?.properties ? (
              <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-8 md:gap-x-12 px-8 py-4 border-b border-opacity-20 border-blue">
                {nft?.properties?.map(property => {
                  return (
                      <div key={property?._id} className="flex items-center opacity-80 text-blue">
                        <span className="font-regular pr-1">{property?.name}:</span>
                        <strong className="font-semiBold">{property?.value}</strong>
                      </div>
                  )
                })}
              </div>
          ) : null}
          <div className="flex flex-col items-start px-8 pb-8 pt-4">
            <p className="text-14 md:text-18 text-blue opacity-80 leading-6 text-justify mt-3">
              {nft?.description}
            </p>
            <a
                href={getOpenseaUrl(nft?.sale?.nftContract, nft?.sale?.tokenId)}
                target={'_blank'}
                rel={'noreferrer noopener'}
                className="flex items-center space-x-2 border border-solid border-blue rounded-12 p-3 mt-4"
            >
              <img src={openSeaLogo} alt="" />
              <div className="text-blue">{t('productPage.Information.ViewOnopenSea')}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
