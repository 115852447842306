import { combineReducers } from "@reduxjs/toolkit";

import user from './user/reducer';
import marketplace from './marketplace/reducer';
import blockchain from './blockchain/reducer';
import preferences from './preferences/reducer';
import notifications from './notifications/reducer';

const rootReducer = combineReducers({
    user,
    marketplace,
    blockchain,
    preferences,
    notifications
})

export const PERSIST_KEYS = ["user", "blockchain", "notifications"];

export default rootReducer
