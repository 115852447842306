import BigNumber from "bignumber.js";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"

export const MarketContractAddress = process.env.REACT_APP_MARKET_ADDRESS || ZERO_ADDRESS;
export const NFTContractAddress = process.env.REACT_APP_NFT_ADDRESS || ZERO_ADDRESS;

export const supportedNFTFormats = [
    'image/png',
    'image/gif',
    'image/webp',
    'image/jpg',
    'image/jpeg',
    'video/avi',
    'video/mp4',

]

export const ZERO = new BigNumber(0);

export const NetworkRPC = process.env.REACT_APP_WEB3_PROVIDER;
