import React, { useEffect, useState } from 'react';
import Layout from '../layouts/MainLayout/MainLayout';
import Explore from '../components/common/Explore';

function ExplorePage() {
  return (
    <Layout mainClassName="mt-1" displayStickySidebar>
      <Explore />
    </Layout>
  );
}

export default ExplorePage;
