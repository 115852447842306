import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useDispatch} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {updateBlockNumber, updateETHPrice} from "./actions";
import useDebounce from "../../hooks/useDebounce";
import coingeckoAPI from "../../api/ethPrice";

export default function Updater() {
    const { library, chainId } = useActiveWeb3React();
    const dispatch = useDispatch();
    const [oldBlockNumber, setOldBlockNumber] = useState(null);


    const [state, setState] = useState({
        chainId,
        blockNumber: null,
        ethPrice: null,
    });

    const getPrices = useCallback(async (tokenIds, vsCurrencies) => {
        const result = await coingeckoAPI.get(`simple/price?ids=${tokenIds.join(',')}&vs_currencies=${vsCurrencies.join(',')}`)

        return result;
    }, [])

    const blockNumberCallback = useCallback(
        (blockNumber) => {
            setOldBlockNumber(bl => {
                if(bl === null) {
                    return blockNumber;
                }
                return bl;
            })

            setState((state) => {
                if (chainId === state.chainId) {
                    if (typeof state.blockNumber !== "number") return { ...state, chainId, blockNumber };
                    return { ...state, chainId, blockNumber: Math.max(blockNumber, state.blockNumber) };
                }
                return state;
            });
        },
        [chainId, setState]
    );

    const ethPriceCallback = useCallback(
        (ethPrice) => {
            setState((state) => {
                if (chainId === state.chainId) {
                    if (typeof state.ethPrice !== "number") return { ...state, chainId, ethPrice };
                    return { ...state, chainId, ethPrice: Math.max(ethPrice, state.ethPrice) };
                }
                return state;
            });
        },
        [chainId, setState]
    )

    useEffect(() => {
        if (!library || !chainId) return undefined;

        if(Math.abs(state.blockNumber - oldBlockNumber) > 100 || state.ethPrice === null) {
            setOldBlockNumber(state.blockNumber);

            getPrices(['ethereum'], ['usd'])
                .then(response => {
                    ethPriceCallback(response?.ethereum?.usd);
                })
                .catch((error) => console.error(`Failed to get eth price for chainId: ${chainId}`, error));
        }

    }, [dispatch, chainId, library, state.blockNumber, ethPriceCallback])

    // attach/detach listeners
    useEffect(() => {
        if (!library || !chainId) return undefined;

        setState({ chainId, blockNumber: null });

        library
            .getBlockNumber()
            .then(blockNumberCallback)
            .catch((error) => console.error(`Failed to get block number for chainId: ${chainId}`, error));

        library.on("block", blockNumberCallback);
        return () => {
            library.removeListener("block", blockNumberCallback);
        };
    }, [dispatch, chainId, library, blockNumberCallback]);

    const debouncedState = useDebounce(state, 100);

    useEffect(() => {
        if (!debouncedState.chainId || !debouncedState.blockNumber) return;
        dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }));
    }, [dispatch, debouncedState.blockNumber, debouncedState.chainId]);

    useEffect(() => {
        if (!debouncedState.chainId || !debouncedState.ethPrice) return;
        dispatch(updateETHPrice({ chainId: debouncedState.chainId, ethPrice: debouncedState.ethPrice }));
    }, [dispatch, debouncedState.chainId, debouncedState.ethPrice]);

    return null;
}
