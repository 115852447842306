import {useMarketContract} from "./useContract";
import {useCallback, useMemo} from "react";
import {sendTransactionHandler} from "../utils/sendTransactionHandler";
import {useWaiter} from "./useWaiter";
import {toast} from "react-toastify";
import {NFTContractAddress} from "../constants/contracts";
import {AddressZero} from "@ethersproject/constants";
import BigNumber from "bignumber.js";
import {useActiveWeb3React} from "./useActiveWeb3React";
import {useLoadingCallback} from "./useLoadingCallback";
import {ETHER} from "../entities/Currency";

export const useReserveAuctionCreator = () => {
    const marketContract = useMarketContract();
    const waiter = useWaiter();
    const { chainId, library, account } = useActiveWeb3React();
    const [loading, callbackHandler] = useLoadingCallback();

    const callback = useCallback(async ({
        tokenId,
        data,
        type = "auction",
        token = ETHER,
        waitForTransaction = true,
        message = undefined
    }) => {
        if(!account || !library || !chainId) return false;

        const methodName = "createReserveAuction";
        const args = [
            NFTContractAddress,
            tokenId?.toString(),
            new BigNumber((type === 'auction' ? data?.reservedPrice : data?.price) || '1').times(10 ** token?.decimals).toFixed(0),
            token === ETHER ? AddressZero : token.address,
            type,
            new BigNumber(data?.royalties).times(100).toFixed(0)
        ]
        const res = await sendTransactionHandler(marketContract, methodName, args);

        if(waitForTransaction) {
            const result = await waiter(res);

            if(result && message) {
                toast.success(message);
            }
            return result || false;
        }

        if(res && message) {
            toast.success(message);
        }
        return res;
    }, [marketContract, waiter, library, chainId, account])

    return useMemo(() => [loading, (...args) => callbackHandler(callback, args)], [loading, callbackHandler, callback]);
}