import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useDispatch, useSelector} from "react-redux";
import Web3 from "web3";
import {connectWallet, disconnectWallet, fetchProfile, login, logout} from "./actions";
import userHandler from "../../api/user";
import {useMemo} from "react";
import checkExpiredJWT from "../../utils/checkExpiredJWT";
import {useHistory} from "react-router-dom";
import RouteMap from "../../routes/RouteMap";

export const useAuthentication = () => {
    const { account } = useActiveWeb3React();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    const address = useSelector(state => state.user.accountAddress);
    const history = useHistory();

    const handleSignMessage = async (address, nonce) => {
        try {
            const web3 = new Web3(Web3.givenProvider);
            if(!web3) {
                dispatch(disconnectWallet());
                return Promise.reject("You need to sign the message to be able to log in.");
            }
            const signature = await web3?.eth.personal.sign(
                nonce,
                address,
                null
            );

            return {
                wallet: address,
                signature,
                message: nonce,
            };
        } catch (err) {
            throw new Error(
                'You need to sign the message to be able to log in.'
            );
        }
    }

    const handleAuthorization = async () => {
        if(!account) {
            dispatch(disconnectWallet());
            return false;
        }

        const tempAddress = account;
        const publicAddress = account?.toLowerCase();
        if(address === null || publicAddress !== address?.toLowerCase()) {
            dispatch(connectWallet({
                address: tempAddress
            }));
        }

        let connectedAddress;
        let nonce;
        try {
            const res = await userHandler.getNonce(publicAddress)
            nonce = res?.data?.nonce;
            connectedAddress = publicAddress;

            const authBody = await handleSignMessage(connectedAddress, nonce)

            const authRes = await userHandler.login(authBody);

            if(authRes) {
                dispatch(connectWallet({
                    address: tempAddress
                }));
                dispatch(login(authRes.data))
                dispatch(fetchProfile());
                history.push(RouteMap.home);
            }

        } catch(e) {
            dispatch(logout());
            return false;
        }
    }

    return useMemo(() => {
        return [isLoggedIn, handleAuthorization];
    }, [isLoggedIn, account])
}

export const useIsWalletConnected = () => {
    const { account } = useActiveWeb3React();
    const accountAddress = useSelector(state => state.user.accountAddress);
    const isConnected = useSelector(state => state.user.isConnected);

    return useMemo(() => {
        return (
            account
            && isConnected
            && accountAddress
            && account?.toLowerCase() === accountAddress?.toLowerCase()
        );
    }, [account, accountAddress])
}

export const useIsLoggedIn = () => {
    const { account } = useActiveWeb3React();
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    const accountAddress = useSelector(state => state.user.accountAddress);
    const accessToken = useSelector(state => state.user.accessToken);
    const expireDate = useSelector(state => state.user.expireDate);

    return useMemo(() => {
        return (
            account
            && isLoggedIn
            && accountAddress
            && account?.toLowerCase() === accountAddress?.toLowerCase()
            && (accessToken && !checkExpiredJWT(expireDate))
        );
    }, [account, isLoggedIn, accountAddress])
}
