import EmptyIcon from '../../assets/images/Empty.svg';
import {useTranslation} from "react-i18next";
import SVG from "react-inlinesvg";
import styled from 'styled-components';

const Icon = styled(SVG)`
  width: 193px;
  height: 333px;
  display: block;
  margin-right: ${({ direction }) => direction === 'vertical' ? "0" : "1.5rem"};
  margin-bottom: ${({ direction }) => direction === 'vertical' ? "1rem" : "0"};
  
  @media (max-width: 768px) {
    width: 115px;
    height: 200px;
    margin-right: ${({ direction }) => direction === 'vertical' ? "0" : "1rem"};
    margin-bottom: ${({ direction }) => direction === 'vertical' ? "0.75rem" : "0"};
    
  }
`

const Container = styled.div`
  max-width: 350px;
`

const EmptyCard = ({
    title = "",
    description = undefined,
    icon = EmptyIcon,
    direction = 'horizontal',
}) => {
    const { t } = useTranslation()
    return (
        <div className={`w-full py-6 flex justify-center ${direction === 'vertical' ? "flex-col items-center" : "flex-row items-end"}`}>
            <Icon
                src={icon}
                direction={direction}
            />
            <Container className="flex flex-col justify-center items-start">
                <span
                    className={`text-24 md:text-28 lg:text-34 text-blue font-bold mb-1`}
                >
                    {title || t("errors.noItemsAvailable")}
                </span>
                {description && (
                    <span
                        className={`text-16 md:text-20 lg:text-24 text-fadeBlue2`}
                    >
                    {description}
                </span>
                )}
            </Container>
        </div>
    )
}

export default EmptyCard;