import httpInstance from "../utils/httpInstance";

export class FirebaseApi {
    constructor(baseURL = `https://api.clubrare.wolfelabs.tech/api/v1/notifications`) {
        this.instance = httpInstance({
            baseURL,
            timeout: 15000
        })

        this.defaultConfig = {
            headers: {
                "Content-Type": "application/json"
            }
        }

    }

    sendToken(token, jwt, config = this.defaultConfig) {
        if(!jwt) {
            return false;
        }
        const reqConfig = this._includeJWT(jwt, config);
        return this.instance.post(`/fcm`, { fcm: token }, reqConfig);
    }

    test(body, jwt, config = this.defaultConfig) {
        const reqConfig = this._includeJWT(jwt, config);
        return this.instance.post(`/test`, body, reqConfig);
    }

    _includeJWT(jwt, config = this.defaultConfig) {
        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${jwt}`
            }
        }
    }

}

const firebaseHandler = new FirebaseApi();

export default firebaseHandler;
