import axios from "axios";
import {toast} from "react-toastify";

export default function httpInstance({ baseURL, timeout, ...attrs }) {
    const instance = axios.create({
        baseURL,
        timeout,
        ...attrs
    })

    instance.interceptors.response.use(res => {
        if("data" in res) {
            return res?.data;
        }

        return res;
    }, err => {
        if('response' in err) {
            const data = err?.response?.data || err?.response;
            if('errors' in data && Array.isArray(data?.errors)) {
                console.log(err);
                for(let i in data?.errors) {
                    toast.error(data?.errors?.[i]?.field?.replace("/", "") + " " + data?.errors?.[i]?.message || "Validation Error");
                }
            } else if(data?.message) {
                toast.error(data?.message || "An Error was occurred, Please try again.")
            } else {
                toast.error("An Error was occurred, Please try again.")
            }
            return Promise.reject(err?.response?.data || err?.response);
        }

        if(err?.message) {
            toast.error(err?.message);
        }
        return Promise.reject(err);
    })

    return instance;
}
