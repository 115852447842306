import {createReducer} from "@reduxjs/toolkit";
import {updateBlockNumber, updateETHPrice} from "./actions";

const initialState = {
    blockNumber: {},
    ethPrice: {
        [3]: 0,
    }
}

export default createReducer(initialState, builder => {
    return builder
        .addCase(updateBlockNumber, (state, action) => {
            const { chainId, blockNumber } = action.payload;
            if (typeof state.blockNumber[chainId] !== "number") {
                state.blockNumber[chainId] = blockNumber;
            } else {
                state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId]);
            }
        })
        .addCase(updateETHPrice, (state, action) => {
            const { chainId, ethPrice } = action.payload;
            if (typeof state.ethPrice[chainId] !== "number") {
                state.ethPrice[chainId] = ethPrice;
            } else {
                state.ethPrice[chainId] = Math.max(ethPrice, state.ethPrice[chainId]);
            }
        })
})
