import React, {useState, useEffect, useRef} from 'react';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import ProfileSummary from '../../components/common/ProfileSummary';
import CategoriesFilter from '../../components/common/CategoriesFilter';
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import BidCard from "../../components/common/BidCard";
import {fetchUserMintedNFTs, fetchUserNFTs, fetchUserSales} from "../../state/marketplace/actions";
import Spinner from "../../components/common/Spinner";
import {useParams} from "react-router-dom";
import userHandler from "../../api/user";
import {toast} from "react-toastify";
import Empty from "../../components/common/Empty";

let isLoading = false;
function UserProfile() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const loader = useRef(null)
  const { userId } = useParams();
  const [profile, setProfile] = useState({});
  const [account, setAccount] = useState('');
  const [query, setQuery] = useState({
    hasMore: true,
    pageNumber: 1,
    pageSize: 12,
    activeCategory: 1,
  })
  const [nfts, setNfts] = useState([]);


  useEffect(() => {
    fetchProfile(userId)
  }, [dispatch, userId]);

  useEffect(() => {
    if(profile?.wallet) {
      setAccount(profile?.wallet);
    }
  }, [profile])


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);


  useEffect(() => {
    if(account) {
      fetchNFTsHandler(account, query);
    }
  }, [query, account])


  const fetchProfile = async id => {
    const userProfileRes = await userHandler.getSingleUser(id);

    if(userProfileRes?.data) {
      setProfile(userProfileRes?.data);
      setLoading(false);
    } else {
      toast.error("User id was invalid")
      history.push('/')
    }
  }


  const fetchNFTsHandler = async (account, query) => {
    if(query.hasMore) {
      isLoading = true;
      let res;
      const params = {
        pageNumber: query.pageNumber,
        pageSize: query.pageSize,
      }
      if(query.activeCategory === 1) {
        res = await dispatch(fetchUserMintedNFTs(account, params))
      } else if(query.activeCategory === 2) {
        res = await dispatch(fetchUserSales(account, params))
      } else {
        res = await dispatch(fetchUserNFTs(account, params));
      }
      if(!res || (res && res?.length < query.pageSize)) {
        setQuery(q => ({
          ...q,
          hasMore: false,
        }))
      }

      if(query.pageNumber === 1) {
        setNfts(res);
      } else {
        setNfts(nfts => [...nfts, ...res]);
      }

      isLoading = false;
    }

  }


  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading) {
      setQuery(q => {
        if(q.hasMore) {
          return {
            ...q,
            pageNumber: q.pageNumber + 1,
          }
        }
        return q;
      })
    }
  };

  const categories = [
    { key: 1, title: t('profile.categories.created') },
    { key: 2, title: t('profile.categories.onSale') },
    { key: 3, title: t('profile.categories.collectibles') },
  ];

  return (
    <MainLayout mainClassName="" loading={loading} displayStickySidebar>
      <div className="flex flex-col space-y-9 sm:space-y-18">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-y-6 gap-x-6 lg:gap-12">
          <ProfileSummary
            wrapperClassName="col-span-1 md:col-span-8 h-full"
            categories={categories}
            activeCategory={query.activeCategory}
            onSetCategories={(catKey) => {
              setNfts([]);
              setQuery(q => ({
                ...q,
                hasMore: true,
                pageNumber: 1,
                activeCategory: catKey
              }))
            }}
            profile={profile}
            editable={false}

          />
          <CategoriesFilter
            categories={categories}
            activeCategory={query.activeCategory}
            onSetCategories={(catKey) => {
              setNfts([]);
              setQuery(q => ({
                ...q,
                hasMore: true,
                pageNumber: 1,
                activeCategory: catKey
              }))
            }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-12">
          {nfts?.map((item) => {
            return (
                <div key={item._id}>
                  <BidCard
                      id={query.activeCategory === 2 ? item?.nft?._id : item?._id}
                      contentClass="lightShadow p-6"
                      details={item}
                      type={query.activeCategory === 2 ? "auction" : "nft"}
                  />
                </div>
            )
          })}
        </div>

        {nfts?.length === 0 && !query.hasMore && (
            <Empty />
        )}
        <div className="flex items-center justify-between py-5 flex-col" ref={loader}>
          {query.hasMore && (
              <Spinner />
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default UserProfile;
