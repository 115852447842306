import {createReducer} from "@reduxjs/toolkit";
import {addMessage, readMessage, updateFCM, updatePermissionRequested, updatePermissionStatus} from "./actions";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    messages: [],
    unreadCount: 0,
    permissions: {
        status: 'default',
        requested: true
    },
    fcm: null
};

export default createReducer(initialState, builder =>
    builder
        .addCase(addMessage, (state, action) => {
            state.messages.push({
                ...action.payload,
                isDone: false,
                _id: uuidv4()
            });
            state.unreadCount++;
        })
        .addCase(readMessage, (state, action) => {
            if(state.unreadCount > 0) {
                state.unreadCount--;
            }

            state.messages = state.messages.map(item => {
                if(item._id === action.payload.id) {
                    return {
                        ...item,
                        isDone: true,
                    }
                }

                return item;
            })
        })
        .addCase(updatePermissionRequested, (state, action) => {
            state.permissions.requested = action.payload || true;
        })
        .addCase(updatePermissionStatus, (state, action) => {
            state.permissions.status = action.payload || "default";
        })
        .addCase(updateFCM, (state, action) => {
            state.fcm = action.payload;
        })
)