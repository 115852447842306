import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '../layouts/MainLayout/MainLayout';
import metamaskLogo from '../assets/images/metamask-fox-wordmark-horizontal.svg';
import walletConnect from '../assets/images/wallet-connect.svg';
import KaikasLogo from '../assets/images/kaikas-wallet.svg';
import {UnsupportedChainIdError, useWeb3React} from "@web3-react/core";
import usePrevious from "../hooks/usePrevious";
import {WalletConnectConnector} from "@web3-react/walletconnect-connector";
import connectors from "../connectors";
import {useDispatch} from "react-redux";
import {connectWallet} from "../state/user/actions";

function ConnectWallet() {
  const { t } = useTranslation();
  const { active, account, connector, activate } = useWeb3React();
  const dispatch = useDispatch();

  const [pendingError, setPendingError] = useState(false);


  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);


  const tryActivation = async (provider) => {
    const connector = provider.provider;

    if(provider.name === 'MetaMask' && (!window.ethereum)) {
      setPendingError("Install Metamask first!");
      return false;
    }

    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
    activate(connector, undefined, true)
        .then(() => {
          dispatch(connectWallet({
            address: account,
          }))
        })
        .catch((error) => {
      if (error instanceof UnsupportedChainIdError) {
        activate(connector);
      } else {
        setPendingError(error?.message || "Something went wrong");
      }
    });
  };

  return (
    <MainLayout
      displaySidebar={false}
      mainClassName="flex flex-col justify-center items-center"
      loading={false}
    >
      <div className="text-18 md:text-24 text-blue font-semibold mt-40">
        {t('connectWallet.title')}
      </div>
      <div className="text-16 md:text-18 text-blue text-center opacity-80 mt-3.5">
        {t('connectWallet.text')}
      </div>
      <button
        className="flex justify-center space-x-4 items-center border border-solid border-white
        rounded-10 mt-6.5 bg-white bg-opacity-20"
        style={{ width: 188, height: 53 }}
        onClick={tryActivation.bind(this, connectors.injected)}
      >
        <img className="w-40" src={metamaskLogo} alt="" />
      </button>
      <button
        className="flex justify-center space-x-4 items-center border border-solid border-white
        rounded-10 mt-4"
        style={{ width: 188, height: 53, boxShadow: '30px 70px 120px rgba(27, 49, 66, 0.2)' }}
        onClick={tryActivation.bind(this, connectors.walletConnect)}
      >
        <img className="w-40" src={walletConnect} alt="" />
      </button>
      <button
        className="flex justify-center space-x-4 items-center border border-solid border-white
        rounded-10 mt-4"
        style={{ width: 188, height: 53, boxShadow: '30px 70px 120px rgba(27, 49, 66, 0.2)' }}
        onClick={tryActivation.bind(this, connectors.walletConnect)}
      >
        <img className="w-40" src={KaikasLogo} alt="" />
      </button>
    </MainLayout>
  );
}

export default ConnectWallet;
