import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import {NetworkContextName} from "./constants";
import getLibrary from "./utils/getLibrary";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import React from "react";
import {Provider} from "react-redux";
import store from "./state/store";
import {StepsProvider} from "./contexts/Steps";
import {NotificationProvider} from "./contexts/Notification";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const Providers = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
                <Provider store={store}>
                    <Router>
                        <StepsProvider>
                            <NotificationProvider>
                                {children}
                            </NotificationProvider>
                        </StepsProvider>
                    </Router>
                </Provider>
                <ToastContainer position={"bottom-center"} />
            </Web3ProviderNetwork>
        </Web3ReactProvider>
    )
}

export default Providers
