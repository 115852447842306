import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Layout from '../../../layouts/MainLayout/MainLayout';
import twitter from '../../../assets/icons/twitterWhite.svg';
import avatar from '../../../assets/images/avatar-2.png';
import WithAuth from "../../../components/hoc/withAuth";
import staticServer from "../../../api/staticServer";
import {useDispatch, useSelector} from "react-redux";
import {fetchProfile, updateProfileAsync} from "../../../state/user/actions";
import getFileURL from "../../../utils/getFileURL";
import {toast} from "react-toastify";
import RouteMap from "../../../routes/RouteMap";
import {useHistory} from "react-router-dom";
import Spinner from "../../../components/common/Spinner";
import Loading from "../../../components/UI/Loading";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const schema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string().max(125),
})

function EditProfile() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(state => state.user.profile);
  const jwt = useSelector(state => state.user.accessToken);

  const avatarFileRef = useRef(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchProfile())
        .then(profile => {
          if(profile && "avatar" in profile) {
            setPreview(profile?.avatar);
          }
          setLoading(false);
        })

  }, []);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    uploadOnStaticServer(selectedFile);
  }, [selectedFile]);

  const uploadOnStaticServer = async (file) => {
    try {
      const res = await staticServer.uploadFile(file, jwt);
      if(res?.success) {
        setPreview(res?.data?.path);
      }
    } catch(e) {
      toast.error(t("errors.uploadFailed"));
    }
  }

  const onFileUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    const body = {
      ...data,
      avatar: preview,
    }

    const res = await dispatch(updateProfileAsync(body));
    setSubmitLoading(false);
    if(res) {
      toast.success(t("editProfile.updatedSuccessfully"))
      history.push(RouteMap.profile.index);
    }
  }

  return (
    <Layout loading={loading}>
      <div className="px-10.5 md:px-0 grid grid-cols-1 md:grid-cols-3">
        <form className="md:col-start-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:mt-6.5 flex flex-col items-center">
            {preview && (
              <img
                className="w-full object-cover
              text-16 lg:text-18 font-semibold rounded-full bg-white bg-opacity-20 border border-solid border-white"
                style={{ height: 124, width: 124 }}
                src={getFileURL(preview || avatar)}
                alt="avatar"
              />
            )}
            <div className="relative">
              <input
                className={`custom-file-input text-transparent cursor-pointer w-full text-16 lg:text-18 font-semibold
               rounded-full bg-gray bg-opacity-50 w-31 h-31
                        border border-solid border-white ${preview ? 'hidden' : 'block'}`}
                style={{ height: 124, width: 124 }}
                onChange={onFileUpload}
                type="file"
                accept="image/*"
                ref={avatarFileRef}
              />
              {!preview && (
                <div
                  className="absolute top-30 lg:top-8 left-3 z-30 text-18 font-semibold cursor-pointer mt-4"
                  style={{ color: '#377CF6' }}
                  onClick={() => avatarFileRef?.current?.click()}
                >
                  Add Picture
                </div>
              )}
            </div>
            {preview && (
              <div
                className="z-30 text-18 font-semibold cursor-pointer mt-4"
                style={{ color: '#377CF6' }}
                onClick={() => avatarFileRef?.current?.click()}
              >
                {t('editProfile.editPicture')}
              </div>
            )}
          </div>
          <div className="text-18 text-blue font-semibold mt-20 lg:mt-9 mb-5.5">
            {t('editProfile.name')}
          </div>
          <input
            className="responsive-placeholder bg-transparent border-b border-solid border-white
                    w-full"
            placeholder="Name"
            defaultValue={profile?.name}
            {...register('name')}
          />
          {errors.name && (
            <p className="text-red justify-self-start mt-2 pl-2">
              {t('editProfile.pleaseEnterName')}
            </p>
          )}

          <div className="text-18 text-blue font-semibold mt-9 mb-5.5">{t('editProfile.bio')}</div>
          <textarea
            className="responsive-placeholder bg-transparent border-b border-solid border-white focus:outline-none
                    w-full"
            placeholder={t('editProfile.bioPlaceholder')}
            defaultValue={profile?.bio}
            {...register('bio')}
          />
          {errors.bio && (
            <p className="text-red justify-self-start mt-2 pl-2">
              {errors?.bio?.message}
            </p>
          )}

          <div className="text-18 text-blue font-semibold mt-9">
            {t('editProfile.verifiedTwitter')}
          </div>
          <div className="inline-flex justify-center items-center space-x-3 mx-auto mt-9 bg-blue rounded-12 p-3">
            <img src={twitter} alt="twitter" />
            <div className="text-18 text-white">@userAccount</div>
          </div>

          <button
            className="w-full text-16 lg:text-14 text-white linearGradient
            flex items-center justify-center
            font-bold rounded-12 py-4 lg:py-3.5 mt-17"
            type="submit"
          >
            {submitLoading ? (<Loading margin={'0'} size={'16px'}/>) : t('editProfile.updateProfile')}
          </button>
        </form>
      </div>
    </Layout>
  );
}

export default WithAuth(EditProfile);
