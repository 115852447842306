import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../layouts/MainLayout/MainLayout';
import BidModal from '../../components/SinglePage/BidModal';
import AuctionSummary from '../../components/SinglePage/AuctionSummary';
import History from '../../components/SinglePage/History';
import Information from '../../components/SinglePage/Information';
import Poster from '../../components/SinglePage/Poster';
import PutOnSale from '../../components/SinglePage/PutOnSale';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchSingleNFT, fetchSingleSale} from "../../state/marketplace/actions";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";

function SingleNFT() {
  const { t } = useTranslation();
  const [bidOpen, setBidOpen] = useState(false);
  const [bidModalTitle, setBidModalTitle] = useState('');
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [NFT, setNFT] = useState({})
    const [bidAmount, setBidAmount] = useState(null);

  useEffect(() => {
    fetchNFT(id);
  }, [id]);

  const fetchNFT = async (id, refetch = false) => {
    setLoading(true);
    if(!refetch) {
        setNFT({});
    }
    const res = await dispatch(fetchSingleNFT(id));
    if('sale' in res) {
        const saleRes = await dispatch(fetchSingleSale(res?.sale?._id));
        if(res) {
            res.sale = saleRes;
        }
    }
    if(res) {
      setNFT(res);
    } else {
        if(!refetch) {
            setNFT({});
        }
    }
    setLoading(false);
  }

  const buyHandler = (bidPrice) => {
      setBidOpen(true);
      setBidAmount(bidPrice);
  }

  const cancelBuy = () => {
      setBidOpen(false);
      setBidAmount(null);
  }

  return (
    <Layout mainClassName="live-auction-lg" displayStickySidebar loading={loading}>
      <BidModal
        bidModalTitle={bidModalTitle}
        open={bidOpen}
        onCloseModal={() => cancelBuy()}
        bid={bidAmount}
        nft={NFT}
      />
      <Poster
          wrapperClass="block md:hidden"
          nft={NFT}
      />
      <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-4 lg:gap-x-2 xl:gap-x-12 gap-y-6 w-full mt-8">
        <div className="row-start-2 md:row-start-1 md:col-start-1 md:col-span-5 lg:col-start-1 lg:col-span-5">
          <Poster
              wrapperClass="hidden md:block"
              nft={NFT}
          />
          <Information nft={NFT} />
        </div>
        <div className="row-start-1 md:row-start-1 md:col-start-6 md:col-span-7 lg:col-start-6 lg:col-span-7 w-full">
          {!NFT.sale?.status
          && (NFT?.owner?.wallet?.toLowerCase() === account?.toLowerCase()
              || !NFT?.owner && NFT?.creator?.toLowerCase() === account?.toLowerCase()) ? (
            <PutOnSale nft={NFT} />
          ) : (
            <AuctionSummary
                nft={NFT}
                onSetBidModalTitle={(title) => setBidModalTitle(title)}
                onSetBidOpen={(bidPrice) => buyHandler(bidPrice)}
            />
          )}
          <History wrapperClass="mt-11.5" bids={NFT?.sale?.bids} saleStatus={NFT?.sale?.status} saleType={NFT?.sale?.type} />
        </div>
      </div>
    </Layout>
  );
}

export default SingleNFT;
