import {toast} from "react-toastify";
import {BigNumber} from "@ethersproject/bignumber";
import {calculateGasMargin} from "./calculateGasMargin";

export const sendTransactionHandler = async (contract, methodName, args, options = {}) => {
    const method = contract[methodName]
    const estimateMethod = contract.estimateGas[methodName];
    const staticCall = contract.callStatic[methodName]

    const estimatedGas = await estimateMethod(...args, options)
        .then(estimatedGas => {
            return estimatedGas
        })
        .catch(async error => {
            await staticCall(...args, options)
                .then((result) => {
                    toast.error("Unexpected issue with estimating the gas. Please try again.")
                })
                .catch((callError) => {
                    toast.error(callError);
                });

            return BigNumber.from(0);
        })



    if(!estimatedGas || estimatedGas?.isZero()) {
        return false;
    }

    const res = await method(...args, {
        ...options,
        gasLimit: calculateGasMargin(estimatedGas),
    })
        .catch(error => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
                toast.error("Transaction rejected by user.");
            } else {
                toast.error(error.message);
            }

            return false;
        })

    return res;

}
