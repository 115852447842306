import {useCallback, useEffect, useMemo, useState} from "react";
import coingeckoAPI from "../api/ethPrice";


export const useTokensPrice = ({tokenIds, vsCurrencies }) => {
    const [prices, setPrices] = useState({});

    const getPrices = useCallback(async (tokenIds, vsCurrencies) => {
        const result = await coingeckoAPI.get(`simple/price?ids=${tokenIds.join(',')}&vs_currencies=${vsCurrencies.join(',')}`)

        setPrices(result);
    }, [])

    useEffect(() => {
        getPrices(tokenIds, vsCurrencies)
    }, [tokenIds, vsCurrencies])

    return prices;
}

export const useTokenPrice = ({ tokenId, vsCurrencies }) => {
    const tokenIds = useMemo(() => [tokenId], [tokenId]);

    return useTokensPrice({
        tokenIds,
        vsCurrencies
    })?.[tokenId] || {}
}

export const useEthPrice = (vsCurrencies = ['usd']) => {
    return useTokenPrice({
        tokenId: "ethereum",
        vsCurrencies
    })
}
