import {useCallback} from "react";
import {useActiveWeb3React} from "./useActiveWeb3React";

export const useWaiter = () => {
    const { library, account } = useActiveWeb3React();

    return useCallback(async (response) => {
        if(!library || !account) {
            return false;
        }

        if(response && 'hash' in response) {
            const { hash } = response;
            try {
                const result = await library.waitForTransaction(hash);
                return result;
            } catch (e) {
                return false;
            }
        }

        return false;
    }, [library, account])
}