import {useCallback, useState} from "react";

const useModal = (initialState = false) => {
    const [open, setOpen] = useState(initialState);

    const toggle = useCallback(() => {
        setOpen(o => !o);
    }, [setOpen])

    return [open, toggle]
}

export default useModal;