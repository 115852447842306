import {useCallback, useMemo, useState} from "react";

export const useLoadingCallback = () => {
    const [loading, setLoading] = useState(false);
    const func = useCallback(async (callback, args = {}) => {
        setLoading(true);
        try {
            const res = await callback(...args);
            setLoading(false);
            return res;
        } catch(e) {
            setLoading(false);
            return false;
        }
    }, [])

    return useMemo(() => [loading, func], [loading, func]);
}