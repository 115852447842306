import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { NetworkConnector } from "./NetworkConnector";

const POLLING_INTERVAL = 15000;
// @ts-ignore
const NETWORK_URL = process.env.REACT_APP_WEB3_PROVIDER;
// @ts-ignore
const CHAIN_ID = Number(process.env.REACT_APP_ENV === 'dev' ? 3 : 1);

const chains = [
    1,
    3,
    1001
]

const networks = {
    "42": NETWORK_URL,
    "1001": "https://api.baobab.klaytn.net:8651/"
}

if (typeof NETWORK_URL === "undefined") {
    throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`);
}

export const network = new NetworkConnector({
    urls: networks,
    defaultChainId: CHAIN_ID
});


export const injected = new InjectedConnector({
    supportedChainIds: chains,
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
    rpc: { 1: NETWORK_URL },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
});


const connectors = {
    injected: {
        provider: injected,
        name: "MetaMask",
    },
    walletConnect: {
        provider: walletconnect,
        name: "Wallet Connect",
    }
};

export default connectors;
