import {useEffect, useMemo, useState} from "react";
import {useMarketContract} from "./useContract";
import {useActiveWeb3React} from "./useActiveWeb3React";

export const useAuctionDetails = (auctionId) => {
    const [auction, setAuction] = useState({});
    const marketContract = useMarketContract();
    const { chainId, account } = useActiveWeb3React();

    useEffect(() => {
        if(!account || !chainId) return false;


        marketContract.getReserveAuction(auctionId)
            .then(res => {
                setAuction(res);
            })
            .catch(err => {
                setAuction({})
            })

    }, [auctionId, chainId, account])

    return useMemo(() => auction, [auction]);
}