import {createContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTestNotification, getToken, onMessageListener, requestPermissionForNotification} from "../services/firebase";
import {addMessage} from "../state/notifications/actions";

const NotificationContext = createContext({
    messages: [],
    unreadCount: 0,
});

const NotificationProvider = ({ children }) => {
    const permissionRequested = useSelector(state => state.notifications.permissions.requested);
    const messages = useSelector(state => state.notifications.messages);
    const unreadCount = useSelector(state => state.notifications.unreadCount);
    const jwt = useSelector(state => state.user.accessToken);
    const dispatch = useDispatch()

    useEffect(() => {
        if(!permissionRequested) {
            requestPermissionForNotification()
        }
    }, [permissionRequested])

    useEffect(() => {
        getToken(jwt)
    }, [jwt])

    useEffect(() => {
        getTestNotification(jwt)
            .then(res => {
                console.log(res)
            })
            .catch(err => console.log(err));
    }, [jwt])

    onMessageListener()
        .then(payload => {
            console.log(payload);

            dispatch(addMessage(payload.data))
        })
        .catch(err => console.log(err));

    return (
        <NotificationContext.Provider value={{ messages, unreadCount }}>
            {children}
        </NotificationContext.Provider>
    )
}

export {
    NotificationProvider,
    NotificationContext
}