import Web3ReactManager from "./components/web3/Web3ReactManager";
import Routes from "./routes";

import UserUpdater from './state/user/updater';
import BlockchainUpdater from './state/blockchain/updater';

function Updaters() {
    return (
        <>
            <UserUpdater/>
            <BlockchainUpdater/>
        </>
    )
}

export default function App() {
    return (
        <Web3ReactManager>
            <Updaters/>
            <Routes/>
        </Web3ReactManager>
    )
}
