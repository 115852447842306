import { createAction } from '@reduxjs/toolkit';
import userHandler from "../../api/user";
import checkExpiredJWT from "../../utils/checkExpiredJWT";

export const connectWallet = createAction("@user/connectWallet");
export const login = createAction('@user/login');
export const logout = createAction('@user/logout');
export const updateProfile = createAction('@user/updateProfile');
export const disconnectWallet = createAction("@user/disconnectWallet");

export const fetchProfile = () => {
    return async (dispatch, getState) => {
        const user = getState().user;

        if(!user.accessToken || checkExpiredJWT(user.expireDate)) {
            dispatch(disconnectWallet());
            return false;
        }
        try {
            const res = await userHandler.getProfile(user.accessToken);
            dispatch(updateProfile({
                profile: res?.data
            }));
            return res?.data;
        } catch(e) {
            return false;
        }
    }
}

export const updateProfileAsync = (body) => {
    return async (dispatch, getState) => {
        const user = getState().user;

        if(!user.accessToken || checkExpiredJWT(user.expireDate)) {
            dispatch(disconnectWallet());
            return false;
        }

        try {
            const res = await userHandler.updateProfile(body, user.accessToken);
            dispatch(updateProfile({
                profile: body
            }));
            return res?.success;
        } catch(e) {
            return false;
        }
    }
}
