import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import thunk from "redux-thunk";

import rootReducer, { PERSIST_KEYS } from "./reducers";

export const store = configureStore({
    reducer: rootReducer,
    middleware: [save({ states: PERSIST_KEYS }), thunk],
    preloadedState: load({ states: PERSIST_KEYS }),
});

export default store;
