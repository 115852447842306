import httpInstance from "../utils/httpInstance";

export class UserApi {
    constructor(baseURL = `https://api.clubrare.wolfelabs.tech/api/v1/users`) {
        this.instance = httpInstance({
            baseURL,
            timeout: 15000
        })

        this.defaultConfig = {
            headers: {
                "Content-Type": "application/json"
            }
        }

    }

    getNonce(address, config = this.defaultConfig) {
        return this.instance.post(
            '/nonce',
            {
                wallet: address
            },
            config
        )
    }

    login(body, config = this.defaultConfig) {
        return this.instance.post('/login', body, config)
    }

    getAll(query, config = this.defaultConfig) {
        const reqConfig = this._includeQuery(query, config);
        return this.instance.get('/', reqConfig);
    }

    getSingleUser(userId, config = this.defaultConfig) {
        return this.instance.get(`/${userId}`, config);
    }

    getProfile(jwt, config = this.defaultConfig) {
        const reqConfig = this._includeJWT(jwt, config);

        return this.instance.get('/profile', reqConfig);
    }

    updateProfile(body, jwt, config = this.defaultConfig) {
        const reqConfig = this._includeJWT(jwt, config);

        return this.instance.patch('/profile', body, reqConfig);
    }

    verifyAccount(body, jwt, config = this.defaultConfig) {
        const reqConfig = this._includeJWT(jwt, config);

        return this.instance.post('/verify', body, reqConfig);
    }

    upVote(userId, jwt, config = this.defaultConfig) {
        const reqConfig = this._includeJWT(jwt, config);

        return this.instance.post(`/${userId}/upvote`, {}, reqConfig);
    }

    _includeJWT(jwt, config = this.defaultConfig) {
        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${jwt}`
            }
        }
    }


    _includeQuery(query = {}, config = this.defaultConfig) {
        if(Object.keys(query).length === 0) {
            return config;
        }

        return {
            ...config,
            params: query,
        }
    }
}

const userHandler = new UserApi();

export default userHandler;
