import {useEthPrice, useTokenPrice} from "./useTokenPrice";
import {useMemo} from "react";
import BigNumber from "bignumber.js";

export const useBalanceValue = ({ tokenId, value, vsCurrency }) => {
    const vsCurrencies = useMemo(() => [vsCurrency], [vsCurrency]);

    const tokenPrice = useTokenPrice({ tokenId, vsCurrencies })?.[vsCurrency] || '0';

    return useMemo(() => {
        return new BigNumber(tokenPrice)
            .times(value)
            .dp(2, 1)
            .toString(10);
    }, [tokenId, value, vsCurrency])
}

export const useEthValue = ({ value, vsCurrency }) => {
    const vsCurrencies = useMemo(() => [vsCurrency], [vsCurrency]);
    const ethPrice = useEthPrice(vsCurrencies)?.[vsCurrency] || '0';


    return useMemo(() => {
        return new BigNumber(ethPrice)
            .times(value)
            .dp(2, 1)
            .toString(10);
    }, [value, vsCurrency])
}
