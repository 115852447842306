import {createReducer} from "@reduxjs/toolkit";
import {
    setLiveAuctions,
    setNFTs,
    setUserNFTs,
    updateConfig,
    updateLeaderboard,
    updateLiveAuctions,
    updateNFTs,
    updateUserNFTs
} from "./actions";

const initialState = {
    liveAuctions: [],
    NFTs: [],
    userNFts: {},
    leaderboard: {
        bestSellers: [],
        bestBuyers: [],
    },
    config: {},
}

export default createReducer(initialState, builder => {
    return builder
        .addCase(setLiveAuctions, (state, action) => {
            state.liveAuctions = action.payload;
        })
        .addCase(updateLiveAuctions, (state, action) => {
            state.liveAuctions = state.liveAuctions.concat(action.payload);
        })
        .addCase(setNFTs, (state, action) => {
            state.NFTs = action.payload;
        })
        .addCase(updateNFTs, (state, action) => {
            state.NFTs = state.NFTs.concat(action.payload);
        })
        .addCase(updateUserNFTs, (state, action) => {
            const { account, nfts } = action.payload
            state.userNFts[account] = state.userNFts[account].concat(nfts)
        })
        .addCase(setUserNFTs, (state, action) => {
            const { account, nfts } = action.payload;
            state.userNFts[account] = nfts;
        })
        .addCase(updateLeaderboard, (state, action) => {
            state.leaderboard = action.payload;
        })
        .addCase(updateConfig, (state, action) => {
            state.config = {
                ...state.config,
                ...action.payload
            }
        })
})
