import React, {useCallback, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CryptoIcon } from './Icons';
import RouteMap from '../../routes/RouteMap';
import moment from "moment";
import Countdown from "react-countdown";
import ipfsToPath from "../../utils/ipfsToPath";
import Web3 from "web3";
import {NetworkRPC} from "../../constants/contracts";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useSelector} from "react-redux";
import BigNumber from "bignumber.js";
import commaNumber from "comma-number";
import isAuctionLive from "../../utils/isAuctionLive";

const web3 = new Web3(new Web3.providers.HttpProvider(NetworkRPC))

const format = commaNumber.bindWith(',', '.')

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
        <div className="text-14 md:text-24 text-blue">
          Auction Ended
        </div>
    );
  } else {
    return (
        <div className="text-14 md:text-16 text-gray flex space-x-1">
          <div className="">{days}d</div>
          <div className="">{hours}h</div>
          <div className="">{minutes}m</div>
          <div className="">{seconds}s</div>
        </div>
    );
  }
};

function BidCard({
  wrapperClass,
  headerClass,
  contentClass,
  height,
  isLiveAuction,
  type = 'auction',
  details = {}
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { account, chainId } = useActiveWeb3React();
  const ethPrice = useSelector(state => state.blockchain.ethPrice[chainId || 1]);

  const nft = useMemo(() => {
    if(type === 'auction') {
      return {
        ...details.nft,
        sale: {
          ...details,
          nft: undefined
        }
      }
    } else {
      return details;
    }
  }, [type, details])

  const id = useMemo(() => {
    return nft?._id || undefined;
  }, [nft])

  const clickHandler = useCallback(() => {
    if(type === 'auction') {
      return history.push(RouteMap.liveAuctions.view(id))
    } else {
      return history.push(RouteMap.nft.view(id));
    }
  }, [id, type])

  const nftPrice = useMemo(() => {
    if(nft && ((nft?.sale?.type === 'auction' && nft?.sale?.reservePrice) || (nft?.sale?.type === 'fixed-price' && nft?.sale?.price))) {
      let price = nft?.sale?.reservePrice || nft?.sale?.price;
      if(nft?.sale?.bids?.length > 0) {
        price = nft?.sale?.bids[nft?.sale?.bids?.length - 1]?.amount || nft?.sale?.reservePrice || nft?.sale?.price ;
      }

      return web3.utils.fromWei(price);
    }
    return 0
  }, [nft])

  const nftValue = useMemo(() => {
    if(!ethPrice) {
      return new BigNumber(nftPrice)?.toFixed(2) || '0.00';
    }
    return new BigNumber(nftPrice).times(ethPrice).toFixed(2);
  }, [ethPrice, nftPrice])

  const isUserBidder = useMemo(() => {
    if(nft?.sale?.bids) {
      const bids = nft?.sale?.bids || [];
      if(bids?.length === 0) {
        return false;
      }

      return bids?.[bids?.length - 1]?.wallet?.toLowerCase() === account?.toLowerCase();
    }

    return false;
  }, [nft, account])

  return (
    <div className={wrapperClass} onClick={clickHandler}>
      <div
        className={`flex flex-col justify-center border border-solid border-white rounded-50 cursor-pointer bg-white bg-opacity-20 ${
          contentClass || 'p-6'
        }`}
      >
        <div className={`flex justify-between ${headerClass}`}>
          <div className="text-18 text-blue font-semibold">{nft?.title}</div>
          {nft?.sale?.type === 'auction' && (
              <div className="text-gray">
                {isAuctionLive(nft?.sale) ? (
                    <Countdown date={moment(nft?.sale?.startDate).add(Math.floor(nft?.sale?.duration / 86400), 'days')} renderer={CountdownRenderer} />
                ) : t('productPage.auctionSummary.AuctionEnded')}
              </div>
          )}
        </div>
        <img
          className="border border-solid border-white rounded-12 mt-3.5 object-contain py-1"
          style={{ height: height || 233 }}
          src={ipfsToPath(nft?.file)}
          alt={nft?.title}
        />
        <div
          className={`flex items-center justify-between bg-white px-5
                      rounded-t-16 rounded-b-40 mt-7 ${
                        nft?.sale ? 'py-4' : 'pt-2 pb-4'
                      }`}
          style={{ height: isLiveAuction ? 99 : 79 }}
        >
          {nft?.sale && (
            <div
              className={`flex ${
                  !isAuctionLive(nft?.sale) && nft?.sale?.bids?.length > 0 ? 'w-full justify-between items-center' : 'flex-col space-y-1'
              }`}
            >
              <div className="text-16 sm:text-18">
                {!isAuctionLive(nft?.sale)
                    ? t('productPage.auctionSummary.SoldFor')
                    : (nft?.sale?.bids?.length === 0 || !nft?.sale?.bids)
                        ? nft?.sale?.type === 'auction' ? t('productPage.auctionSummary.MinimumBid') : t('productPage.auctionSummary.Price')
                        : t('profile.bid.highestBid')
                }
              </div>
              <div className="flex items-center space-x-2">
                <div className="sm:text-18 sm:font-semibold">{nftPrice}</div>
                <CryptoIcon />
                <div className="text-12 sm:text-14 opacity-50">${format(nftValue)}</div>
              </div>
            </div>
          )}
          {(isAuctionLive(nft?.sale) || nft?.sale?.seller?.toLowerCase() === account?.toLowerCase() || !nft?.sale?.status) && (
            <button
              className={`text-18 bg-blue px-6 py-3 text-white rounded-10 text-center ${
                  (nft?.sale?.seller?.toLowerCase() === account?.toLowerCase() && !isAuctionLive(nft?.sale)) || !nft?.sale?.status
                      ? 'w-full rounded-b-30' 
                      : 'rounded-br-30'
              }`}
              style={{ boxShadow: '10px 10px 15px 7px rgba(27, 49, 66, 0.13)' }}
              onClick={() => history.push(RouteMap.liveAuctions.view(nft?._id))}
            >
              {nft?.owner?.toLowerCase() === account?.toLowerCase()
                  ? (!isAuctionLive(nft?.sale) ? "Put on Sale" : "Cancel Order")
                  : nft?.sale?.type !== 'auction' ? (!nft?.sale || nft?.sale?.status === 'canceled' ? t('productPage.auctionSummary.showDetails') : t('productPage.auctionSummary.BuyNow'))
                      : isAuctionLive(nft?.sale)
                          ? t('profile.bid.placeABid')
                          : isUserBidder
                              ? t('profile.bid.ClaimNFT')
                              : t('profile.bid.showResult')
              }
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
//
export default BidCard;
