import React, {useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../UI/Modal';
import closeBox from '../../assets/icons/closeBox.svg';
import { CryptoIcon } from '../common/Icons';
import commaNumber from "comma-number";
import Web3 from "web3";
import {NetworkRPC} from "../../constants/contracts";
import BigNumber from "bignumber.js";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";
import {useSelector} from "react-redux";
import {sendTransactionHandler} from "../../utils/sendTransactionHandler";
import {useMarketContract} from "../../hooks/useContract";
import ipfsToPath from "../../utils/ipfsToPath";
import {toast} from "react-toastify";

const format = commaNumber.bindWith(',', '.')
const web3 = new Web3(new Web3.providers.HttpProvider(NetworkRPC))

function BidModal({open, onCloseModal, bidModalTitle, bid, nft}) {
    const {t} = useTranslation();
    const [accepted, setAccepted] = useState(false);
    const [displayErrMsg, setDisplayErrMsg] = useState(false);
    const {chainId} = useActiveWeb3React();
    const ethPrice = useSelector(state => state.blockchain.ethPrice[chainId || 1]);
    const marketContract = useMarketContract()

    const bidPrice = useMemo(() => {
        if(!bid) {
            return 0;
        }
        return web3.utils.fromWei(bid);
    }, [bid])

    const bidValue = useMemo(() => {
        return new BigNumber(bidPrice).times(ethPrice).toFixed(2);
    }, [ethPrice, bidPrice])


    const onBuyFixedPrice = async () => {
        const args = [nft?.sale?.saleId];
        const options = {
            value: bid,
        }
        const methodName = 'completeOrderETH';
        const res = await sendTransactionHandler(marketContract, methodName, args, options);
        const {hash} = res;
        if (!hash) {
            return false;
        }

        return hash;
    }

    const onBidAuction = async () => {
        const args = [nft?.sale?.saleId];
        const options = {
            value: bid,
        }
        const methodName = 'placeBidETH';
        const res = await sendTransactionHandler(marketContract, methodName, args, options);
        const {hash} = res;
        if (!hash) {
            return false;
        }

        return hash;
    }

    const onCheckout = async () => {
        let res;
        try {
            if(nft?.sale?.type === 'auction') {
                res = await onBidAuction();
            } else {
                res = await onBuyFixedPrice();
            }
        } catch(e) {
            res = false;
        }

        if(!res) {
            toast.error("Please try again. Confirm the transaction and make sure you are paying enough gas!")
        } else {
            if(nft?.sale?.type === 'auction') {
                toast.success("Your bid submitted successfully!");
            } else {
                toast.success("Your order completed!");
            }
            onCloseModal()
        }
    }

    const title = (
        <div className="grid grid-cols-3 items-center border-gray w-full border-b border-solid border-fadeBlue">
            <div className="col-start-2 text-18 md:text-22 text-center font-semibold p-4.5">
                {bidModalTitle || t('productPage.bidModal.PlaceABid')}
            </div>
            <div
                className="col-start-3 justify-self-end mr-9 cursor-pointer"
                onClick={() => {
                    setAccepted(false);
                    setDisplayErrMsg(false);
                    onCloseModal();
                }}
            >
                <img className="" src={closeBox} alt="close"/>
            </div>
        </div>
    );

    const content = (
        <div className="flex flex-col py-9.5 px-12">
            <div className="text-16 md:text-18 text-blue font-semibold">
                {t('productPage.bidModal.Item')}
            </div>
            <div className="flex items-center space-x-5 mt-4">
                <div className="gradient-box">
                    <img className="rounded-12 w-full" src={ipfsToPath(nft?.file)} alt={nft?.title}/>
                </div>
                <div className="flex flex-col space-y-2.5">
                    <div className="text-18 md:text-22 text-blue font-semibold">{nft?.title}</div>
                    <div className="flex items-center space-x-2">
                        <div className="text-16 md:text-20 text-blue font-bold">{bidPrice}</div>
                        <CryptoIcon/>
                    </div>
                    <div className="text-14 md:text-16 text-blue opacity-60">${format(bidValue)}</div>
                </div>
            </div>
            <div className="mt-8">
                <div className="colorful-checkbox">
                    <label>
                        <input type="checkbox" name="checkbox" onClick={() => setAccepted((bool) => !bool)}/>
                        <span className="text-blue font-semibold">
              {t('productPage.bidModal.TermsOfService')}
            </span>
                    </label>
                </div>
                {displayErrMsg && <p className="text-red text-14 pl-9">Please Accept Terms Of Service</p>}
            </div>
            <div className="w-full mt-8">
                <button
                    className="w-full text-16 md:text-20 text-white font-semibold bg-blue rounded-12 rounded-b-30 py-4 md:py-3.5"
                    style={{boxShadow: '10px 20px 25px 7px rgba(27, 49, 66, 0.13)'}}
                    onClick={() => {
                        if (!accepted) {
                            setDisplayErrMsg(true);
                        } else {
                            onCheckout()
                        }
                    }}
                >
                    {t('productPage.bidModal.Submit')}
                </button>
            </div>
        </div>
    );

    return (
        <Modal
            title={title}
            open={open}
            content={content}
            onCloseModal={() => {
                setAccepted(false);
                setDisplayErrMsg(false);
                onCloseModal();
            }}
            containerClass="rounded-50"
        />
    );
}


export default BidModal;
