import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-drawer/assets/index.css';
import './assets/styles/customStyles.scss';
import './assets/styles/utilityStyles.scss';
import './assets/styles/tailwind.css';
import './i18n';
import Providers from "./Providers";
import App from "./App";
import {registerServiceWorker} from "./serviceWorker";

ReactDOM.render(
    <Providers>
        <App/>
    </Providers>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerServiceWorker();
