import {useActiveWeb3React} from "./useActiveWeb3React";
import {useMemo} from "react";
import {getContract} from "../utils/getContract";
import {MarketContractAddress, NFTContractAddress} from "../constants/contracts";

import NFT721_ABI from '../constants/abis/NFT721.json';
import MARKET_ABI from '../constants/abis/NFTMarket.json';

export function useContract(address, ABI, withSignerIfPossible = true) {
    const { account, library } = useActiveWeb3React();

    return useMemo(() => {
        if (!address || !ABI || !library) return null;
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
        } catch (error) {
            console.error("Failed to get contract", error);
            return null;
        }
    }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useNFTContract() {
    return useContract(NFTContractAddress, NFT721_ABI);
}


export function useMarketContract() {
    return useContract(MarketContractAddress, MARKET_ABI);
}
