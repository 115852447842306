import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../layouts/MainLayout/MainLayout';
import BidCard from '../../components/common/BidCard';
import {useDispatch} from "react-redux";
import {fetchNFTs, fetchUsers} from "../../state/marketplace/actions";
import Spinner from "../../components/common/Spinner";
import Dropdown from "../../components/UI/Dropdown";
import filter from "../../assets/icons/filter.svg";
import {useQuery} from "../../hooks/useQuery";
import RouteMap from "../../routes/RouteMap";
import {useHistory, useParams} from "react-router-dom";
import Empty from "../../components/common/Empty";
import UserCard from "../../components/Search/UserCard";

let isLoading = false;

const filterItems = [
    { key: 1, title: 'Tokens', value: 'nft' },
    { key: 2, title: 'Sales', value: 'auction' },
    { key: 3, title: 'Users', value: 'user' },
];


const searchTypes = {
    nft: "NFT",
    user: "People"
}

function Search() {
    const { t } = useTranslation();
    const [result, setResult] = useState([]);
    const loader = useRef(null)
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedFilter, setSelectedFilter] = useState(filterItems[0]);
    const [parsedQuery, query, setQuery] = useQuery();
    const [page, setPage] = useState({
        hasMore: true,
        pageNumber: 1,
        pageSize: 12,
        sort: '-createdAt',
        params: {}
    })
    const { type } = useParams()

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };

        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, []);


    useEffect(() => {
        fetchAuctionsHandler(page);
    }, [page])


    useEffect(() => {
        if(!parsedQuery?.q) {
            history.push(RouteMap.home);
        } else {
            setResult([]);
            setPage(p => ({
                ...p,
                pageNumber: 1,
                hasMore: true,
                params: {
                    ...p.params,
                    q: parsedQuery.q,
                }
            }))
        }
    }, [query])

    useEffect(() => {
        if(type) {
            setResult([]);
            setPage(p => ({
                ...p,
                pageNumber: 1,
                hasMore: true,
            }))
        }
    }, [type])


    const fetchAuctionsHandler = async (page) => {
        if(page.hasMore && page.params.q) {
            isLoading = true;
            let res;
            if(type === 'user') {
                res =  await dispatch(fetchUsers({
                    pageNumber: page.pageNumber,
                    pageSize: page.pageSize,
                    sort: page.sort,
                    ...page.params,
                }))
            } else {
                res = await dispatch(fetchNFTs({
                    pageNumber: page.pageNumber,
                    pageSize: page.pageSize,
                    sort: page.sort,
                    ...page.params,
                }))
            }

            if(!res || (res && res?.length < page.pageSize)) {
                setPage(q => ({
                    ...q,
                    hasMore: false,
                }))
            }

            if(page.pageNumber === 1) {
                setResult(res);
            } else {
                setResult(oldResult => [...oldResult, ...res]);
            }

            isLoading = false;
        }
    }

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isLoading) {
            setPage(q => {
                if(q.hasMore) {
                    return {
                        ...q,
                        pageNumber: q.pageNumber + 1,
                    }
                }
                return q;
            })
        }
    };


    const resetHandler = (q) => {
        dispatch(setResult([]));
        return {
            ...q,
            pageNumber: 1,
            hasMore: true,
        }
    }

    const sortHandler = (value) => {
        setPage(q => ({
            ...resetHandler(q),
            sort: value,
            params: {}
        }))
    }

    const filterHandler = (field, value) => {
        setPage(q => ({
            ...resetHandler(q),
            sort: "-createdAt",
            params: {
                ...q.params,
                [field]: value
            }
        }))
    }


    const sortItems = [
        { key: 1, title: t('home.explore.filterItems.MostRecent'), onClick: sortHandler.bind(this, "-createdAt") },
        { key: 2, title: t('home.explore.filterItems.Cheapest'), onClick: sortHandler.bind(this, "+reservePrice") },
        { key: 3, title: t('home.explore.filterItems.HighestPrice'), onClick: sortHandler.bind(this, "-reservePrice") },
        { key: 4, title: t('home.explore.filterItems.Sold'), onClick: filterHandler.bind(this, "status", "completed") },
    ];


    return (
        <Layout mainClassName="mt-1" displayStickySidebar>
            <div className="flex justify-between items-center">
                <div className="text-18 lg:text-24 text-blue font-semibold mr-4 lg:mr-5 xl:mr-8.5">
                    {t('header.search')} "{parsedQuery?.q}" in {searchTypes[type || "nft"]}
                </div>
                <div className="flex items-center ml-auto">
                    <div className="ml-4 -mr-4 lg:mr-0 lg:ml-0">
                        <Dropdown
                            title={
                                <img
                                    className="border border-solid border-white rounded-12"
                                    src={filter}
                                    alt="filter"
                                />
                            }
                            items={sortItems}
                            menuButtonWrapperClass="-top-2"
                            menuButtonClass="rounded-10 justify-between text-white"
                            menuItemsClass="bg-white rounded-32 w-36 mt-2 py-5 -left-26 top-8"
                            menuItemClass="text-16 text-gray font-semibold justify-center py-2"
                            selectedItemClass="text-blue"
                            width="w-12"
                            displayChevronDown={false}
                        />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-y-9 gap-x-8 md:gap-x-12 mt-16 md:mt-8  fullHD:grid-cols-4">
                {result?.map((item) => {
                    return type === 'nft' ? (
                        <div key={item._id}>
                            <BidCard
                                id={item._id}
                                contentClass="lightShadow p-6"
                                details={item}
                                type={'nft'}
                                height={286}
                            />
                        </div>
                    ) : (
                        <div key={item._id}>
                            <UserCard
                                id={item._id}
                                profile={item}
                            />
                        </div>
                    )
                })}
            </div>
            {result?.length === 0 && !page.hasMore && (
                <Empty description={t('errors.noItemSearch')} />
            )}
            <div className="flex items-center justify-between py-5 flex-col">
                <div ref={loader} />
                {page.hasMore && (
                    <Spinner />
                )}
            </div>
        </Layout>
    );
}

export default Search;
