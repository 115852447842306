import {useIsLoggedIn, useIsWalletConnected} from "../../state/user/hooks";
import {useHistory} from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import RouteMap from "../../routes/RouteMap";
import {useActiveWeb3React} from "../../hooks/useActiveWeb3React";

const WithAuth = (Component) => {
    return props => {
        const { account } = useActiveWeb3React()
        const isWalletConnected = useIsWalletConnected();
        const isLoggedIn = useIsLoggedIn();
        const history = useHistory();
        const { t } = useTranslation()

        let content = (
            <MainLayout loading={true}>
            </MainLayout>
        )

        if(isLoggedIn) {
            content = <Component/>
        } else {
            if(isWalletConnected || account) {
                toast.error(t('connectWallet.signMessage'));
            }
            history.push(RouteMap.connectWallet);
        }

        return content;
    }
}

export default WithAuth;
