import httpInstance from "../utils/httpInstance";

export class MarketPlaceApi {
    constructor(baseURL = `https://api.clubrare.wolfelabs.tech/api/v1/marketplace`) {
        this.instance = httpInstance({
            baseURL,
            timeout: 15000
        })

        this.defaultConfig = {
            headers: {
                "Content-Type": "application/json"
            }
        }

    }

    getNFTs(query = {}, config = this.defaultConfig) {
        const reqConfig = this._includeQuery(query, config);
        return this.instance.get('/nfts', reqConfig);
    }

    getSingleNFT(nftId, config = this.defaultConfig) {
        return this.instance.get(`/nfts/${nftId}`, config);
    }

    createNFT(body, jwt, config = this.defaultConfig) {
        const reqConfig = this._includeJWT(jwt, config);

        return this.instance.post('/nfts', body, reqConfig);
    }

    liveSales(query = {}, config = this.defaultConfig) {
        const reqConfig = this._includeQuery(query, config);

        return this.instance.get('/sales', reqConfig);
    }

    liveAuctions(query = {}, config = this.defaultConfig) {
        return this._typeFilteredSales("auction", query, config);
    }

    liveOrders(query = {}, config = this.defaultConfig) {
        return this._typeFilteredSales("fixed-price", query, config);
    }

    singleLiveSale(saleId, config = this.defaultConfig) {
        return this.instance.get(`/sales/${saleId}`, config);
    }


    _typeFilteredSales(type = "auction", query = {}, config = this.defaultConfig) {
        const reqQuery = {
            ...query,
            type
        }

        return this.liveSales(reqQuery, config);
    }

    _includeQuery(query = {}, config = this.defaultConfig) {
        if(Object.keys(query).length === 0) {
            return config;
        }

        return {
            ...config,
            params: query,
        }
    }

    _includeJWT(jwt, config = this.defaultConfig) {
        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${jwt}`
            }
        }
    }


}

const marketPlaceHandler = new MarketPlaceApi();

export default marketPlaceHandler;
