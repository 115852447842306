import {createReducer} from "@reduxjs/toolkit";
import {connectWallet, disconnectWallet, login, logout, updateProfile} from "./actions";
import moment from "moment";

const initialState = {
    accessToken: null,
    isLoggedIn: false,
    isConnected: false,
    profile: {},
    accountAddress: null,
    expireDate: ''
}

export default createReducer(initialState, builder =>
    builder
        .addCase(connectWallet, (state, action) => {
            const { address } = action.payload;
            state.accountAddress = address;
            state.isConnected = true;
        })
        .addCase(disconnectWallet, (state, action) => {
            state = initialState;
            return state;
        })
        .addCase(login, (state, action) => {
            const { token } = action.payload;
            state.isLoggedIn = true;
            state.accessToken = token;
            state.expireDate = moment().add(7, 'days').format('x');
        })
        .addCase(logout, (state, action) => {
            state.isLoggedIn = false;
            state.accessToken = null;
            state.expireDate = '';
        })
        .addCase(updateProfile, (state, action) => {
            const { profile } = action.payload;
            state.profile = {
                ...state.profile,
                ...profile,
            };
        })
)
